import {useFormInstanceIsReadOnly, useLocalFormInstance, useSentryAndToast} from "@store";
import React, {useEffect, useMemo, useRef} from "react";

interface NullAnswersToastProps {
  formInstanceId: string;
}
// deprecated versions saved some answers as null,
// all questions are be disabled in FormQuestionComponent
// complete/sign actions can still be performed
export const ToastNullAnswersError: React.FC<NullAnswersToastProps> = ({formInstanceId}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const sentryAndToast = useSentryAndToast();
  const readOnly = useFormInstanceIsReadOnly(formInstanceId);
  const toastShownRef = useRef(false);

  const hasNullAnswers = useMemo(() => {
    if (!formInstance?.answers) {
      return false;
    }
    return formInstance.answers.some((a) => a === null);
  }, [formInstance?.answers]);

  // log error and show warning if null answers are found
  useEffect(() => {
    if (hasNullAnswers && formInstance?._id && !toastShownRef.current && !readOnly) {
      sentryAndToast(
        `Error in answer!. Please contact support.`,
        new Error(`Null answers found for form instance ${formInstance._id}`)
      );
      toastShownRef.current = true;
      console.warn("Null answers found");
    }
  }, [hasNullAnswers, formInstance?._id, sentryAndToast, readOnly]);

  return null; // This component does not render any UI
};
