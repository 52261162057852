export var THEME;
(function (THEME) {
    THEME["DARK"] = "dark";
    THEME["LIGHT"] = "light";
})(THEME || (THEME = {}));
export var SCALING;
(function (SCALING) {
    SCALING["FIXED"] = "fixed";
    SCALING["SCALE"] = "scale";
})(SCALING || (SCALING = {}));
export var ENCODING;
(function (ENCODING) {
    ENCODING["BASE64"] = "base64";
    ENCODING["BINARY"] = "binary";
})(ENCODING || (ENCODING = {}));
