// Odd functions that don't fit elsewhere in mongooseSlices

import {baseUrl, getAuthToken, ListResponse, populateId} from "@ferns-rtk";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {isFamilyMember, isPatient, isSuperUser, UserTypes} from "@utils";
import axios from "axios";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import {ExpoPushToken} from "expo-notifications";
import * as Updates from "expo-updates";
import {printDate} from "ferns-ui";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import {IMessage, MessageDeliveryStatus} from "../components/giftedChat";
import {APPOINTMENT_CONFIG, RecurringType} from "../constants";
import {ConsentForm, ConsentFormContents} from "../constants/ConsentFormContents";
import {
  Address,
  Avatar,
  CarePod,
  ConsentFormAgreement,
  ConsentFormType,
  Conversation,
  FamilyUnit,
  InsurancePlan,
  Message,
  ScheduleItem,
  ScheduleItemType,
  StaffRoles,
  SupervisorRoles,
  User,
} from "./modelTypes";

export const linkFitbit = createAsyncThunk("utils/linkFitbit", async () => {
  const token = await getAuthToken();
  const response = await axios.get(`${baseUrl}/linkFitbit`, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  return response.data.data?.url;
});

export const userName = (user?: {name: User["name"]}): string => user?.name ?? "";
export const isOutOfOffice = (user: User): boolean => Boolean(user?.outOfOffice);

export const pushEnabled = (user: User): boolean =>
  Boolean(user?.expoTokens.length && user?.pushNotifications);

export const getCurrentExpoToken = async (): Promise<ExpoPushToken> => {
  let tokenRes: ExpoPushToken;
  if (__DEV__) {
    const appConfig = require("../app.json");
    const projectId = appConfig?.expo?.extra?.eas?.projectId;
    tokenRes = await Notifications.getExpoPushTokenAsync({
      projectId,
    });
  } else {
    tokenRes = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    });
  }
  return tokenRes;
};

// Ignores super user, only the user facing staff roles.
export const getStaffRole = (user: {
  staffRoles: User["staffRoles"];
}):
  | StaffRoles.Psychiatrist
  | StaffRoles.Therapist
  | StaffRoles.PatientGuide
  | StaffRoles.FamilyGuide
  | undefined => {
  if (!user?.staffRoles) {
    return undefined;
  }
  if (user.staffRoles.Psychiatrist) {
    return StaffRoles.Psychiatrist;
  } else if (user.staffRoles.Therapist) {
    return StaffRoles.Therapist;
  } else if (user.staffRoles.PatientGuide) {
    return StaffRoles.PatientGuide;
  } else if (user.staffRoles.FamilyGuide) {
    return StaffRoles.FamilyGuide;
  } else {
    return undefined;
  }
};

export function getStaffRoles(user: User): StaffRoles[] {
  const userStaffRoles: StaffRoles[] = [];
  if (isSuperUser(user)) {
    userStaffRoles.push(StaffRoles.SuperUser);
  }
  for (const role of Object.values(StaffRoles)) {
    if (user?.staffRoles?.[role]) {
      userStaffRoles.push(role);
    }
  }
  return userStaffRoles;
}

// Returns an array with all users in a conversation except the user provided who is removed
export const otherConversationUsers = (conversation: Conversation, userId: string): User[] => {
  return conversation.users.filter((u) => u.userId?._id !== userId).map((u) => u.userId) as User[];
};

/**
 * Checks to see if an object has object properties that are empty. Will only go down one level.
 * We are ignoring the _id property that we may retrieve from mongo/mongoose.
 *
 * example:
 * * RETURNS TRUE
 * const obj = {
 *   nested1: {},
 *   nested2: {}
 * }
 *
 * * RETURNS FALSE
 * const obj = {
 *    nested1: {},
 *    nested2: {
 *      name: 'why are we this deep'
 *    }
 * }
 */
export const isEmptyNestedObject = (obj: any): boolean => {
  // This is a way to remove the _id property from the object being examined in order
  // to check if all other properties are empty.
  const {_id, ...emptyObj} = obj;
  // Returns true if all nested objects within the parent object are falsy or empty
  // objects themselves
  return every(emptyObj, (val) => {
    return !Boolean(val) || isEmpty(val);
  });
};

export const isInCaseload = (careTeam: User["careTeam"], staffId: string): boolean => {
  return Boolean(
    Object.values(careTeam).find((u) => {
      return u?._id === staffId || u === staffId;
    })
  );
};

interface SortUsersByType {
  patientsInCaseload: User[];
  patientsNotInCaseload: User[];
  familyMembersInCaseload: User[];
  familyMembersNotInCaseload: User[];
  staff: User[];
}

export const sortUsersByAssigned = (
  users: User[],
  staffUser: User | undefined
): SortUsersByType => {
  if (!staffUser) {
    return {
      patientsInCaseload: [],
      familyMembersInCaseload: [],
      patientsNotInCaseload: [],
      familyMembersNotInCaseload: [],
      staff: [],
    };
  }

  const patientsInCaseload: User[] = [];
  const patientsNotInCaseload: User[] = [];

  const familyMembersInCaseload: User[] = [];
  const familyMembersNotInCaseload: User[] = [];
  const testStaff: User[] = [];
  const nonTestStaff: User[] = [];

  users.forEach((u) => {
    if (u?.careTeam && staffUser && isInCaseload(u.careTeam, staffUser?._id)) {
      if (isPatient(u.type)) {
        patientsInCaseload.push(u);
      } else if (isFamilyMember(u.type)) {
        familyMembersInCaseload.push(u);
      }
    } else if (isPatient(u.type)) {
      patientsNotInCaseload.push(u);
    } else if (isFamilyMember(u.type)) {
      familyMembersNotInCaseload.push(u);
    } else {
      if (u.testUser) {
        testStaff.push(u);
      } else {
        nonTestStaff.push(u);
      }
    }
  });
  const sortByName = (a: User, b: User): number => (a.name > b.name ? 1 : -1);

  patientsInCaseload.sort(sortByName);
  patientsNotInCaseload.sort(sortByName);
  familyMembersInCaseload.sort(sortByName);
  familyMembersNotInCaseload.sort(sortByName);
  testStaff.sort(sortByName);
  nonTestStaff.sort(sortByName);

  const staff = [...nonTestStaff, ...testStaff];

  return {
    patientsInCaseload,
    patientsNotInCaseload,
    familyMembersInCaseload,
    familyMembersNotInCaseload,
    staff,
  };
};

export const prioritizeSelectedUserFamilyMembers = (
  selectedUser: User | undefined,
  users: User[],
  familyUnits: FamilyUnit[] | undefined
): User[] => {
  /**
   * return the list of users as is if these conditions are met
   */
  if (!selectedUser || !familyUnits || !isUserInFamilyUnits(selectedUser, familyUnits)) {
    return users;
  }

  // Pull patients from the selectedUser's familyUnits
  const prioritizedFamilyMembers: User[] = [];
  for (const family of familyUnits) {
    if (isUserInFamilyUnit(selectedUser, family)) {
      // Filter out the selectedUser. We should never get to the empty array but we'll check for it
      // just in case const allFamilyUsers = family?.familyUsers.filter((user) => user !==
      // selectedUser._id) || []; Concat to result array of patients prioritizedFamilyMembers =
      // prioritizedFamilyMembers.concat(allFamilyUsers);
    }
  }
  const nonFamilyMembers: User[] = [];
  const familyUnitMembers: User[] = [];

  // We use a for loop to preserve the order of the users list
  for (const user of users) {
    const family = prioritizedFamilyMembers.find((member) => user._id === member._id);
    if (family) {
      familyUnitMembers.push(user);
    } else {
      nonFamilyMembers.push(user);
    }
  }

  return [...familyUnitMembers, ...nonFamilyMembers];
};

// The regex in this fx checks for one uppercase character, one lowercase character,
// one digit and one special symbol
export const passwordHasErrors = (
  selectedUserType: string | null,
  password: string
): string | undefined => {
  if (selectedUserType === UserTypes.Staff && password.length < 12) {
    return "Staff password must be at least 12 characters in length";
  }
  if (selectedUserType !== UserTypes.Staff && password.length < 8) {
    return "User password must be at least 8 characters in length";
  }

  let critera = 0;
  if (/^(?=.*[a-z])/.test(password)) {
    critera++;
  }
  if (/^(?=.*[A-Z])/.test(password)) {
    critera++;
  }
  if (/(?=.*\d)/.test(password)) {
    critera++;
  }
  if (/(?=.*(\W|_))/.test(password)) {
    critera++;
  }
  if (critera < 3) {
    return "Password must include at least three of the following: uppercase character, lower case character, digit, and special symbol";
  }
};

export function isCarepodType(val: string | CarePod): boolean {
  return typeof val !== "string";
}

export function roundToHour(date: Date): Date {
  const p = 60 * 60 * 1000; // milliseconds in an hour
  return new Date(Math.round(date.getTime() / p) * p);
}

export function printAddress(address: Address): string {
  if (!address?.address1) {
    return "";
  }
  return `${address.address1} ${address.address2 || ""} ${address.city}, ${address.state}, ${
    address.zipcode
  }`;
}

export function userIsDisabled(user?: Pick<User, "disabled">): boolean {
  return Boolean(user?.disabled);
}

export function userIsLocked(user?: Pick<User, "attempts">): boolean {
  return (user?.attempts ?? 0) >= 3;
}

export function getObjectDifferences(obj1: any, obj2: any): any {
  const differences: any = {};
  for (const key in obj1) {
    if (!isEqual(obj1[key], obj2[key])) {
      differences[key] = obj1[key];
    }
  }
  return differences;
}

export function shouldShowConsentForm(type: ConsentFormType, user?: User): boolean {
  if (!user) {
    return false;
  }
  const latestConsentForm = latestConsentFormOfType(type);
  if (!latestConsentForm) {
    return false;
  }

  // Ensure we only send the relevant agreement.
  if (
    (isPatient(user?.type) && type === "familyMemberAgreement") ||
    (isFamilyMember(user?.type) && type === "patientAgreement")
  ) {
    return false;
  }

  const consentForm = user.consentFormAgreements.find(
    (cf) => cf.consentFormType === type && cf.consentFormId === latestConsentForm.consentFormId
  );
  return !consentForm || consentForm.consentFormId < latestConsentForm.consentFormId;
}

export function shouldShowAnyConsent(user: User): boolean {
  const types: ConsentFormType[] = [
    "patientAgreement",
    "familyMemberAgreement",
    "consent",
    "transportation",
    "research",
    "hipaa",
    "privacy",
  ];
  for (const type of types) {
    if (shouldShowConsentForm(type, user)) {
      return true;
    }
  }
  return false;
}

export function latestSignedConsentForm(
  user: User,
  type: ConsentFormType
): ConsentFormAgreement | undefined {
  const latestConsentForm = latestConsentFormOfType(type);
  if (!latestConsentForm) {
    return undefined;
  }
  return sortBy(
    user.consentFormAgreements.filter((cf) => cf.consentFormType === type) ?? [],
    "consentFormId"
  )[0];
}

export function latestConsentFormOfType(type: ConsentFormType): ConsentForm | undefined {
  return ConsentFormContents.filter((cf) => cf.consentFormType === type).sort((a, b) =>
    a.consentFormId > b.consentFormId ? -1 : 1
  )[0];
}

export function guessUserLastName(user?: Pick<User, "name">): string {
  return user?.name?.split(" ").pop() || "";
}

export function isUserInFamilyUnit(user: User, family: FamilyUnit): boolean {
  return Boolean(family.familyUsers.find((u) => u._id === user._id));
}

export function isUserInFamilyUnits(user: User, familyUnits: FamilyUnit[]): boolean {
  for (const family of familyUnits) {
    const isInFamily = Boolean(family.familyUsers.find((u) => u._id === user._id));
    if (isInFamily) {
      return true;
    }
  }
  return false;
}

export function filterScheduleItemsByCarePods(
  scheduleItems: ScheduleItem[],
  carePodIds: string[]
): ScheduleItem[] {
  return scheduleItems.filter((s) => {
    const carePods = s.users.map((u) => u.userId.carePod);
    return carePods.some((carePod) => carePod && carePodIds.includes(carePod));
  });
}

export function filterScheduleItemsByStaff(
  scheduleItems: ScheduleItem[],
  staffIds: string[]
): ScheduleItem[] {
  return scheduleItems.filter((s) =>
    s.staff.some(
      (staffMember) => staffMember.userId?._id && staffIds.includes(staffMember.userId?._id)
    )
  );
}

export function filterScheduleItemsByUsers(
  scheduleItems: ScheduleItem[],
  userIds: string[]
): ScheduleItem[] {
  return scheduleItems.filter((s) =>
    s.users.some((user) => user.userId?._id && userIds.includes(user.userId?._id))
  );
}

interface VersionInfo {
  environment: "production" | "staging" | "publish-on-merge" | "dev" | "unknown" | null;
  dev: boolean;
  updateChannel: string;
  version: string;
}

export function versionInfo(): VersionInfo {
  return {
    dev: Boolean(__DEV__),
    // According to https://docs.expo.dev/versions/latest/sdk/updates/ the Updates.channel is the suggested way to check
    // for apps. For web, we need to use the manifest.
    updateChannel: Updates.channel ?? (Constants.manifest2?.metadata as any)?.channel ?? "unknown",
    environment: Constants.expoConfig?.extra?.APP_ENV ?? (Boolean(__DEV__) ? "dev" : "unknown"),
    // According to https://docs.expo.dev/versions/latest/sdk/constants/ the expoConfig is the suggested way to check
    // version, and handles expo-updates
    version: (Updates.manifest as any)?.version ?? Constants.expoConfig?.version ?? "Unknown",
  };
}
export const substituteDotPhrases = (
  text: string,
  phrases: {phrase: string; replacement: string}[]
): string => {
  const replaceDotPhrase = (inputText: string): string => {
    for (const {phrase, replacement} of phrases) {
      // Uses a positive lookahead to ensure that the dot phrase is followed by a space (' ')
      // character only, excluding other types of whitespace like new lines.
      // The global flag ('g') allows this regex to find and replace every occurrence throughout the
      // entire input text. This enables the function to handle dot phrases inserted anywhere within
      // the text, even if added after initial typing in the middle of a paragraph.
      const regex = new RegExp(`\\.${phrase}(?=[ ])`, "g");
      inputText = inputText.replace(regex, replacement);
    }
    return inputText;
  };

  return replaceDotPhrase(text);
};

const getDeliveryStatuses = (m: Message): MessageDeliveryStatus[] => {
  const statuses: MessageDeliveryStatus[] = [];
  if (!m.notifications) {
    return statuses;
  }
  for (const n of m.notifications) {
    if (!n || !n._id) {
      continue;
    }
    // avoid undefined case so we have something to show in the UI
    const status = n.status ?? "Unknown";
    if (n.sendAsPush) {
      statuses.push({
        _id: n._id.toString(),
        status,
        hasError: n.deliveryErrors && n.deliveryErrors?.length > 0,
        errorMessage: n.deliveryErrors?.map((error) => error).join(", "),
        type: "push",
      });
    } else if (n.sendAsSms) {
      statuses.push({
        _id: n._id.toString(),
        status,
        hasError: n.deliveryErrors && n.deliveryErrors?.length > 0,
        errorMessage: n.deliveryErrors?.map((error) => error).join(", "),
        type: "sms",
      });
    }
  }
  return statuses;
};

// Convert our messages to the messages gifted chat expects
export const convertMessageToGifted = (m: Message, avatarList: Avatar[]): IMessage => {
  return {
    _id: m._id,
    text: m.text!,
    createdAt: m.created,
    deliveryStatuses: getDeliveryStatuses(m),
    // Fetch from user store, or treat as populated
    user: {
      _id: m?.from?._id ?? "",
      name: userName(m.from as any),
      avatar: avatarList.find((a) => a.ownerId === m?.from?._id)?.imageMediaLink,
      type: m.from?.type,
    },
    conversationId: m.conversationId,
    sentAsSms: m.sentAsSms,
    system: !m?.from,
  };
};

export const getBrowserTabTitle = (unreadCount?: number, tagCount?: number): string => {
  if (tagCount && unreadCount) {
    return `(${tagCount > 10 ? "10+" : tagCount} Mention${tagCount === 1 ? "" : "s"}, ${
      unreadCount > 10 ? "10+" : unreadCount
    } Unread) Flourish Health`;
  } else if (unreadCount) {
    return `(${unreadCount > 10 ? "10+" : unreadCount} Unread) Flourish Health`;
  } else {
    return "Flourish Health";
  }
};

export const splitOnUpperCase = (str: string): string => {
  return str.split(/(?=[A-Z])/).join(" ");
};

export const getKeyByValue = (object: any, value: any): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

// Recursively remove any empty keys from an object
export const removeEmptyKeys = (obj: any): any => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === undefined || value === "") {
        delete obj[key];
      } else if (typeof value === "object") {
        removeEmptyKeys(value); // Recursively call the function for nested objects
        if (Object.keys(value).length === 0) {
          delete obj[key]; // Remove the key if the nested object is empty after recursion
        }
      }
    }
  }
  return obj;
};

export const isBillingInfoFinished = (user: User): boolean => {
  return Boolean(user.billingInfo.insurancePlan) && Boolean(user.billingInfo.memberId);
};

export const isBillable = (user: User, insurancePlans?: ListResponse<InsurancePlan>): boolean => {
  return populateId(user.billingInfo?.insurancePlan, insurancePlans)?.billable ?? false;
};

export const isAcceptingReferrals = (
  user: User,
  insurancePlans?: ListResponse<InsurancePlan>
): boolean => {
  return populateId(user.billingInfo?.insurancePlan, insurancePlans)?.acceptingReferrals ?? false;
};

export const hasPreferredPharmacy = (user: User): boolean => {
  return Boolean(user.preferredPharmacies.length > 0);
};

export const getScheduledItemFor = (
  user: User,
  type: "Psychiatry Intake" | "Therapy Intake" | "In Home Onboarding Visit",
  scheduledItems?: ScheduleItem[]
): ScheduleItem | undefined => {
  const items = scheduledItems?.filter(
    (item) => item.type === type && item.users?.find((u) => u.userId?._id === user._id)
  );
  if (!items || items.length === 0) {
    return undefined;
  }
  // Return the most recent one.
  return items.sort((a, b) => {
    if (!a.startDatetime || !b.startDatetime) {
      return 0;
    }
    return new Date(b.startDatetime).getTime() - new Date(a.startDatetime).getTime();
  })[0];
};

export const hasScheduledItemFor = (
  user: User,
  type: "Psychiatry Intake" | "Therapy Intake" | "In Home Onboarding Visit",
  scheduledItems?: ScheduleItem[]
): string => {
  const item = getScheduledItemFor(user, type, scheduledItems);
  if (item) {
    return printDate(item.startDatetime);
  } else {
    return "";
  }
};

export function isSupervisor(user?: User): boolean {
  if (!user) {
    return false;
  }
  return SupervisorRoles.some((role) => Boolean(user.staffRoles?.[role]));
}

export function isSuperUserOrSupervisor(user: User | undefined): boolean {
  if (!user || !user?.staffRoles) {
    return false;
  }
  return [...SupervisorRoles, StaffRoles.SuperUser].some((role) => user.staffRoles[role]);
}

export function separateOnUpperCase(str: string): string {
  return str.split(/(?=[A-Z])/).join(" ");
}

// Automatically set the title based on the type and users/staff to match how the care team
// was naming these events before.

// TODO: Add tests for appointment helpers.
export const generateScheduleItemTitle = (
  newType: ScheduleItemType | "",
  newUsers: User[],
  newStaff: User[],
  recurring: keyof typeof RecurringType
): string => {
  if (!newType || !APPOINTMENT_CONFIG[newType]?.title) {
    return "";
  }
  let newTitle = APPOINTMENT_CONFIG[newType]?.title ?? "";
  if (recurring === RecurringType.Weekly) {
    newTitle = `Weekly ${newTitle}`;
  }
  if (newUsers.length && newStaff.length) {
    const patients = newUsers.filter((u) => isPatient(u.type));
    const familyMembers = newUsers.filter((u) => isFamilyMember(u.type));

    const usersName = patients.length ? patients[0].name : familyMembers[0].name;

    const staffName = newStaff[0].name;

    if (usersName && staffName) {
      return `${newTitle}: ${staffName} <> ${usersName}`;
    } else {
      return newTitle;
    }
  } else {
    return newTitle;
  }
};

export const generateScheduleItemLocation = ({
  type,
  users,
  staff,
}: {
  type: ScheduleItemType;
  users: User[];
  staff: User[];
}): {location: string; helperText: string} => {
  const appointmentConfig = APPOINTMENT_CONFIG[type];
  if (!appointmentConfig) {
    console.warn(`No appointment config found for type: ${type}`);
    return {location: "", helperText: ""};
  }

  let location = "";
  let helperText = "";

  if (appointmentConfig.video) {
    // If this is a clinical intake, we always want to use the guide's link.
    if (type === "Clinical Intake" || type === "Guide Clinical Intake") {
      const guide = staff?.find((s) => s.staffRoles.PatientGuide && Boolean(s.videoChatLink));
      if (guide?.videoChatLink) {
        helperText = `Automatically set to ${guide.name}'s video chat link.`;
        location = guide.videoChatLink;
      }
    } else {
      // In all other cases, grab the first staff with a video chat link.
      const videoStaff = staff?.find((s) => Boolean(s.videoChatLink));
      if (videoStaff?.videoChatLink) {
        helperText = `Automatically set to ${videoStaff.name}'s video chat link.`;
        location = videoStaff.videoChatLink;
      }
    }
  } else if (type === "In Home Onboarding Visit" || type === "In Home Guide Visit") {
    // Grab the first user with an address.
    const selectedAddressUser = users.find((m) => m.address?.address1);
    // For in person, automatically use the address of the first user.
    if (selectedAddressUser) {
      helperText = `Autofilled address of ${selectedAddressUser.name}`;
      location = printAddress(selectedAddressUser.address);
    }
  }

  return {location, helperText};
};

export const getUserFriendlyNotificationTimeString = (minutesBefore: number): string => {
  if (minutesBefore === 60) {
    return "1 hour before";
  } else if (minutesBefore === 1440) {
    return "1 day before";
  } else {
    return `${minutesBefore} minutes before`;
  }
};

export const getColorForAppointmentType = (type?: ScheduleItemType): string => {
  if (!type) {
    return "red";
  }
  const config = APPOINTMENT_CONFIG[type];
  if (config?.group === "Therapy") {
    return "green";
  } else if (config?.group === "Psychiatry") {
    return "blue";
  } else if (config?.group === "Guide") {
    return "purple";
  } else if (config?.group === "Enrollment") {
    return "orange";
  } else {
    return "red";
  }
};

export function getFormInternalKeysAssocWithApptMap(): Map<string, string> {
  // ex return:
  // Map {
  //  "form-internal-key" => "Appt Type",
  //  "therapy-progress" => "Therapy - Patient Session"
  // }

  return new Map(
    Object.keys(APPOINTMENT_CONFIG)
      .filter(
        (key) => APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG]?.associatedFormConfig
      )
      .map((key) => [
        APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG]?.associatedFormConfig
          ?.internalKey!,
        key,
      ])
  );
}
