import {useGetUsersByIdQuery, usePatchUsersByIdMutation, User} from "@store";
import {isStaff} from "@utils";
import {useToast} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {TapToEditRow} from "./TapToEditRow";

interface TapToEditGoogleDriveProps {
  userId: string;
  isEditing?: boolean;
}

export const TapToEditGoogleDrive = ({
  userId,
  isEditing,
}: TapToEditGoogleDriveProps): React.ReactElement | null => {
  const toast = useToast();
  const [updateUser] = usePatchUsersByIdMutation();
  // This should always hit the cache.
  const {data: userData} = useGetUsersByIdQuery(userId);

  const [user, setUser] = useState<User | undefined>(undefined);

  // This is a hack to get the user data to load in the UI. It's not ideal, but it works.
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  return (
    <TapToEditRow
      isEditing={isEditing}
      setValue={(value: string): void => {
        setUser({...user, growth: {...user.growth, documentFolder: value.trim()}});
      }}
      title="Google Drive"
      type="url"
      value={user.growth?.documentFolder ?? ""}
      onSave={async (value): Promise<void> => {
        if (!value.startsWith("https://drive.google.com/drive/folders/")) {
          toast.error("Document Folder must be a Google Drive folder link");
          return;
        }
        await updateUser({
          id: user._id,
          body: {
            growth: {...user.growth, documentFolder: value.trim()},
          },
        });
      }}
    />
  );
};
