import {
  useArchiveWorkflowMapping,
  useCurrentWorkflowMapping,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  isOutOfOffice,
  pushEnabled,
  setUserSettings,
  useAppDispatch,
  useGetFitbitStatusesQuery,
  useGetUsersByIdQuery,
  useGetUserSessionsQuery,
  useOutboundPhoneCallMutation,
  usePatchUserSettingsByIdMutation,
  userIsDisabled,
  userIsLocked,
  userName,
  useSelectUserSettings,
  useSelectWorkflowStaffId,
  useUpdateLastReadMutation,
} from "@store";
import {
  isGuideOnly,
  IsMobileDevice,
  isSuperUser,
  isTestUser,
  isWorkflowCompleted,
  isWorkflowPinned,
  isWorkflowSnoozed,
} from "@utils";
import {Box, Button, Heading, IconButton, useTheme, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useMemo, useState} from "react";

import {UserBadge} from "../UserBadge";
import {CriticalEventHelpModal} from "./CriticalEventHelpModal";
import {SnoozeWorkflowModal} from "./SnoozeModal";

interface ShowMoreOptionsProps {
  showMore: boolean;
  isMyWorkflow: boolean;
  isWorkflowMappingSnoozed: boolean;
  isWorkflowMappingCompleted: boolean;
  canArchive: boolean;
  conversation: any;
  hasUnread: boolean;
  unsnoozeWorkflow: () => Promise<void>;
  setShowModal: (showModal: boolean) => void;
  completeWorkflow: () => Promise<void>;
  archiveWorkflowMapping: () => void;
  markRead: () => Promise<void>;
  markUnread: () => Promise<void>;
}

const ShowMoreOptions: React.FC<ShowMoreOptionsProps> = ({
  showMore,
  isMyWorkflow,
  isWorkflowMappingSnoozed,
  isWorkflowMappingCompleted,
  canArchive,
  conversation,
  hasUnread,
  unsnoozeWorkflow,
  setShowModal,
  completeWorkflow,
  archiveWorkflowMapping,
  markRead,
  markUnread,
}) => {
  if (!showMore) return null;

  return (
    <Box alignSelf="end" direction="row" marginTop={1}>
      {Boolean(isMyWorkflow) && (
        <>
          <Box marginRight={2}>
            <IconButton
              accessibilityLabel="Snooze"
              iconName="clock"
              tooltipText="Snooze"
              variant={isWorkflowMappingSnoozed ? "muted" : "secondary"}
              onClick={async () => {
                isWorkflowMappingSnoozed ? await unsnoozeWorkflow() : setShowModal(true);
              }}
            />
          </Box>
          <Box marginRight={2}>
            <IconButton
              accessibilityLabel="Complete"
              iconName="circle-check"
              tooltipText="Complete"
              variant={isWorkflowMappingCompleted ? "muted" : "secondary"}
              onClick={completeWorkflow}
            />
          </Box>
        </>
      )}
      {Boolean(canArchive) && (
        <Box marginRight={Boolean(conversation) ? 2 : 0}>
          <IconButton
            accessibilityLabel="Archive"
            confirmationText="Are you sure you want to archive this user?"
            iconName="box-archive"
            tooltipText="Archive"
            variant="secondary"
            withConfirmation
            onClick={archiveWorkflowMapping}
          />
        </Box>
      )}
      {Boolean(conversation) && (
        <IconButton
          accessibilityLabel={hasUnread ? "Mark Read" : "Mark Unread"}
          iconName={hasUnread ? "envelope" : "envelope-open"}
          tooltipText={hasUnread ? "Mark Read" : "Mark Unread"}
          variant="secondary"
          onClick={hasUnread ? markRead : markUnread}
        />
      )}
    </Box>
  );
};

export const ChatViewHeader = (): ReactElement | null => {
  const dispatch = useAppDispatch();
  const profile = useReadProfile();
  const staffId = useSelectWorkflowStaffId();
  const toast = useToast();
  const userSettings = useSelectUserSettings();
  const {theme} = useTheme();

  const archiveWorkflowMapping = useArchiveWorkflowMapping();
  const [outboundPhoneCall] = useOutboundPhoneCallMutation();
  const [updateUserSettings] = usePatchUserSettingsByIdMutation();
  const [updateLastRead] = useUpdateLastReadMutation();

  const currentWorkflowMapping = useCurrentWorkflowMapping();
  const userId = currentWorkflowMapping?.userId?._id; // Patient, family member, or staff user the workflow mapping is about
  const {data: displayedUser} = useGetUsersByIdQuery(userId ?? skipToken);
  const {data: userSessionsData} = useGetUserSessionsQuery(userId ? {ownerId: userId} : skipToken);
  const {conversation} = useGetConversationForWorkflowMapping(
    staffId ? currentWorkflowMapping : undefined
  );

  const {data: fitbitStatusData} = useGetFitbitStatusesQuery(userId ? {userId} : skipToken);
  const fitbitData = fitbitStatusData?.data?.[0];
  const timeSinceLastSync = fitbitData?.lastSync
    ? Math.floor(DateTime.now().diff(DateTime.fromISO(fitbitData?.lastSync)).minutes)
    : 0;

  const [showSnoozeModal, setShowModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const {
    snoozed: isWorkflowMappingSnoozed,
    pinned: isWorkflowMappingPinned,
    completed: isWorkflowMappingCompleted,
  } = useMemo(() => {
    const snoozed =
      userSettings && currentWorkflowMapping
        ? isWorkflowSnoozed(currentWorkflowMapping._id, userSettings)
        : false;

    const pinned =
      userSettings && currentWorkflowMapping
        ? isWorkflowPinned(currentWorkflowMapping._id, userSettings)
        : false;

    const completed =
      userSettings && currentWorkflowMapping
        ? isWorkflowCompleted(currentWorkflowMapping._id, userSettings)
        : false;

    return {snoozed, pinned, completed};
  }, [currentWorkflowMapping, userSettings]);

  if (IsMobileDevice || !displayedUser || !profile) {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);
  const canArchive = Boolean(staffId && (isMyWorkflow || isSuperUser(profile)));
  const hasUnread = Boolean(conversation?.unreadCount);
  const doNotDisturb = Boolean(userSessionsData?.data?.[0]?.doNotDisturb);

  const completeWorkflow = async (): Promise<void> => {
    const userSettingsMutation = await updateUserSettings({
      id: userSettings._id,
      body: {
        panelConfig: {
          reorganizedWorkflowMappings: [
            ...(userSettings?.panelConfig?.reorganizedWorkflowMappings?.filter(
              (swm) => swm.workflowMappingId !== currentWorkflowMapping?._id
            ) || []),
            {
              workflowMappingId: currentWorkflowMapping?._id,
              completed: !isWorkflowMappingCompleted,
              pinned: false,
              snoozedUntil: undefined,
            },
          ],
        },
      },
    })
      .unwrap()
      .catch(toast.catch);
    if (!userSettingsMutation) {
      return;
    }

    dispatch(setUserSettings(userSettingsMutation));
  };

  // If the workflow is already snoozed, remove from the snoozed list
  const unsnoozeWorkflow = async (): Promise<void> => {
    const userSettingsMutation = await updateUserSettings({
      id: userSettings._id,
      body: {
        panelConfig: {
          reorganizedWorkflowMappings: [
            ...(userSettings?.panelConfig?.reorganizedWorkflowMappings?.filter(
              (swm) => swm.workflowMappingId !== currentWorkflowMapping?._id
            ) || []),
          ],
        },
      },
    })
      .unwrap()
      .catch(toast.catch);
    if (!userSettingsMutation) {
      return;
    }

    dispatch(setUserSettings(userSettingsMutation));
  };

  const pinWorkflow = async (): Promise<void> => {
    const userSettingsMutation = await updateUserSettings({
      id: userSettings._id,
      body: {
        panelConfig: {
          reorganizedWorkflowMappings: [
            ...(userSettings?.panelConfig?.reorganizedWorkflowMappings?.filter(
              (rwm) => rwm.workflowMappingId !== currentWorkflowMapping?._id
            ) || []),
            {
              workflowMappingId: currentWorkflowMapping?._id,
              pinned: !isWorkflowMappingPinned,
              snoozedUntil: undefined,
              completed: false,
            },
          ],
        },
      },
    })
      .unwrap()
      .catch(toast.catch);
    if (!userSettingsMutation) {
      return;
    }

    dispatch(setUserSettings(userSettingsMutation));
  };

  const markRead = async (): Promise<void> => {
    if (!conversation) {
      console.error("No conversation to mark read");
      return Promise.resolve();
    }

    await updateLastRead({
      conversationId: conversation._id,
      lastReadDateTime: new Date(),
    });
  };

  const markUnread = async (): Promise<void> => {
    if (!conversation) {
      console.error("No conversation to mark unread");
      return;
    }

    const lastRead = conversation.lastMessageSentDate
      ? DateTime.fromISO(conversation.lastMessageSentDate)
      : DateTime.now();
    await updateLastRead({
      conversationId: conversation._id,
      lastReadDateTime: lastRead.minus({seconds: 1}).toJSDate(),
    })
      .unwrap()
      .catch(toast.catch);
  };

  // TODO: Move workflow user badges and archive/mark read buttons into the header
  return (
    <Box
      alignItems="center"
      borderBottom="default"
      color="base"
      dangerouslySetInlineStyle={{
        __style: {
          borderTopLeftRadius: theme.primitives.radiusMd,
          borderTopRightRadius: theme.primitives.radiusMd,
        },
      }}
      direction="column"
      padding={3}
      width="100%"
    >
      <Box alignItems="baseline" direction="row" flex="grow" width="100%">
        <Box alignItems="start" width="70%">
          <Heading size="sm">{userName(displayedUser)}</Heading>
          <Box direction="row" width="100%" wrap>
            {isOutOfOffice(displayedUser) && <UserBadge status="error" title="Out of Office" />}
            {isGuideOnly(displayedUser) && <UserBadge status="info" title="Guide-Only" />}
            {isTestUser(displayedUser) && <UserBadge status="neutral" title="Test User" />}
            {userIsDisabled(displayedUser) && <UserBadge status="error" title="Disabled" />}
            {userIsLocked(displayedUser) && <UserBadge status="warning" title="Locked" />}
            {!pushEnabled(displayedUser) && (
              <>
                <UserBadge status="warning" title="Push Disabled" />
                {timeSinceLastSync > 3 && (
                  <UserBadge
                    status="warning"
                    title={`Fitbit Not Synced in ${timeSinceLastSync} Days`}
                  />
                )}
              </>
            )}
            {doNotDisturb && <UserBadge status="warning" title="Do Not Disturb" />}
          </Box>
        </Box>
        <Box alignItems="end" paddingY={1} width="30%">
          <Box alignItems="center" direction="row" gap={2}>
            <IconButton
              accessibilityLabel="Call User"
              iconName="phone"
              variant="secondary"
              onClick={async (): Promise<void> => {
                toast.show(`Calling user "${displayedUser.name}"`);
                try {
                  await outboundPhoneCall({
                    callRecipient: {
                      id: displayedUser._id,
                      phoneNumber: displayedUser.phoneNumber,
                      name: displayedUser.name,
                    },
                  }).unwrap();
                } catch (error: any) {
                  toast.error(`${error?.data?.title ?? "Unknown error calling user"}`);
                }
              }}
            />

            {Boolean(isMyWorkflow) && (
              <IconButton
                accessibilityLabel="Pin"
                iconName="thumbtack"
                tooltipText={isWorkflowMappingPinned ? "Unpin" : "Pin"}
                variant={isWorkflowMappingPinned ? "muted" : "secondary"}
                onClick={pinWorkflow}
              />
            )}
            {Boolean(isMyWorkflow) && (
              <Button text="Help" variant="outline" onClick={() => setShowHelpModal(true)} />
            )}

            {Boolean(isMyWorkflow || conversation || canArchive) && (
              <IconButton
                accessibilityLabel="alert center filter toggle"
                iconName={showMore ? "chevron-up" : "chevron-down"}
                tooltipText={showMore ? "Collapse" : "Show More Options"}
                variant="secondary"
                onClick={() => setShowMore(!showMore)}
              />
            )}
          </Box>
          <ShowMoreOptions
            archiveWorkflowMapping={archiveWorkflowMapping}
            canArchive={canArchive}
            completeWorkflow={completeWorkflow}
            conversation={conversation}
            hasUnread={hasUnread}
            isMyWorkflow={isMyWorkflow}
            isWorkflowMappingCompleted={isWorkflowMappingCompleted}
            isWorkflowMappingSnoozed={isWorkflowMappingSnoozed}
            markRead={markRead}
            markUnread={markUnread}
            setShowModal={setShowModal}
            showMore={showMore}
            unsnoozeWorkflow={unsnoozeWorkflow}
          />
        </Box>
        {showSnoozeModal && <SnoozeWorkflowModal hideModal={() => setShowModal(false)} />}
        {Boolean(userId) && (
          <CriticalEventHelpModal
            associatedUserId={userId!}
            setHelpModal={setShowHelpModal}
            showHelpModal={showHelpModal}
          />
        )}
      </Box>
    </Box>
  );
};
