import BaseEmbedItem from './base-embed-item';
import { Refreshable } from './mixins/refreshable';
import { Filterable } from './mixins/filterable';
import { getDashboardUrl, getSharedEmbedOptions } from './utils';
import { SCALING, } from './types';
import DashboardChart from './dashboard-chart';
const getDashboardOptions = (options) => {
    if (typeof options !== 'object' || options === null) {
        throw new Error('Options argument must be an object');
    }
    const sharedEmbedOptions = getSharedEmbedOptions(options);
    const { dashboardId, chartsBackground, widthMode, heightMode, showTitleAndDesc, charts, } = options;
    // Verify dashboard embed options
    if (typeof dashboardId !== 'string' || dashboardId.length === 0) {
        throw new Error('dashboardId must be specified');
    }
    if (chartsBackground !== undefined && typeof chartsBackground !== 'string') {
        throw new Error('chartsBackground must be a string if specified');
    }
    if (widthMode !== undefined && typeof widthMode !== 'string') {
        throw new Error('widthMode must be a string if specified');
    }
    if (widthMode !== undefined &&
        widthMode !== SCALING.FIXED &&
        widthMode !== SCALING.SCALE) {
        throw new Error(`widthMode must be "${SCALING.FIXED}" or "${SCALING.SCALE}"`);
    }
    if (heightMode !== undefined && typeof heightMode !== 'string') {
        throw new Error('heightMode must be a string if specified');
    }
    if (heightMode !== undefined &&
        heightMode !== SCALING.FIXED &&
        heightMode !== SCALING.SCALE) {
        throw new Error(`heightMode must be "${SCALING.FIXED}" or "${SCALING.SCALE}"`);
    }
    if (showTitleAndDesc !== undefined && typeof showTitleAndDesc !== 'boolean') {
        throw new Error('showTitleAndDesc must be a boolean value if specified');
    }
    if (charts !== undefined && !Array.isArray(charts)) {
        throw new Error('charts embedding option must be an array if specified');
    }
    if (charts !== undefined) {
        // check if chartIds are unique
        const uniqueIds = {};
        charts.forEach((ch) => (uniqueIds[ch.chartId] = uniqueIds.chartId));
        if (Object.keys(uniqueIds).length !== charts.length) {
            throw new Error('charts embedding option must contain unique chartIds');
        }
    }
    return {
        ...sharedEmbedOptions,
        dashboardId,
        chartsBackground,
        widthMode,
        heightMode,
        showTitleAndDesc,
        charts,
    };
};
class DashboardEventSender extends BaseEmbedItem {
    /** @ignore */
    constructor(options) {
        super();
        this.name = 'Embedded Dashboard';
        this.ERRORS = {
            SEND: 'Dashboard has not been rendered. Ensure that you wait for the promise returned by `dashboard.render()` before trying to manipulate a dashboard.',
            IFRAME: 'A dashboard can only be rendered into a container once',
        };
        this.COLOUR = {
            LIGHT: '#F1F5F4',
            DARK: '#12212C',
        };
        this.options = getDashboardOptions(options);
    }
    getEmbedUrl() {
        return getDashboardUrl(this.options);
    }
}
/**
 * # Dashboard
 *
 * Allows you to interact and embed dashboards into your application.
 *
 * ```js
 * const sdk = new EmbedSDK({ ... });
 * const dashboard = sdk.createDashboard({ ... });
 *
 * // renders a dashboard
 * dashboard.render(document.getElementById('embed-dashboard'));
 *
 * ```
 */
class Dashboard extends Filterable(Refreshable(DashboardEventSender)) {
    constructor() {
        super(...arguments);
        this.charts = {};
    }
    /**
     * Sends the `ready` event to Charts together with the chart options
     * to render the embedded dashboard in the container
     * @param container where the dashboard will render
     */
    async render(container) {
        await super.render(container);
        // Ready to actually render Embedded Dashboard
        await this._send('ready', this.options.charts);
    }
    /**
     * @returns current chartsBackground or empty string if not set
     */
    async getChartsBackground() {
        const [result] = await this._send('get', 'chartsBackground');
        return typeof result === 'string'
            ? result
            : Promise.reject('unexpected response received from iframe');
    }
    /**
     * Set a custom background color for all charts.
     * To clear existing value, set it to empty string.
     */
    async setChartsBackground(value) {
        if (typeof value !== 'string') {
            return Promise.reject('chartsBackground property value should be a string');
        }
        await this._send('set', 'chartsBackground', value);
    }
    /**
     * @returns whether attribution logo should be shown
     */
    async isShowAttribution() {
        const [result] = await this._send('get', 'attribution');
        return typeof result === 'boolean'
            ? Boolean(result)
            : Promise.reject('unexpected response received from iframe');
    }
    /**
     * Enable/Disable attribution logo.
     */
    async setShowAttribution(value) {
        if (typeof value !== 'boolean') {
            return Promise.reject('showAttribution property value should be a boolean');
        }
        await this._send('set', 'attribution', value);
    }
    /**
     * @returns get width scaling mode of embedded dashboard
     */
    async getWidthMode() {
        const [result] = await this._send('get', 'scalingWidth');
        return result === SCALING.FIXED || result === SCALING.SCALE
            ? result
            : Promise.reject('unexpected response received from iframe');
    }
    /**
     * Set width scaling mode for embedded dashboard
     */
    async setWidthMode(value) {
        if (!['fixed', 'scale'].includes(value)) {
            return Promise.reject('widthMode property value should be a string value of "fixed" or "scale"');
        }
        await this._send('set', 'scalingWidth', value);
    }
    /**
     * @returns get height scaling mode of embedded dashboard
     */
    async getHeightMode() {
        const [result] = await this._send('get', 'scalingHeight');
        return result === 'fixed' || result === 'scale'
            ? result
            : Promise.reject('unexpected response received from iframe');
    }
    /**
     * Set height scaling mode for embedded dashboard
     */
    async setHeightMode(value) {
        if (!['fixed', 'scale'].includes(value)) {
            return Promise.reject('heightMode property value should be a string value of "fixed" or "scale"');
        }
        await this._send('set', 'scalingHeight', value);
    }
    /**
     * @returns get the dashboard chart with specified id
     */
    async getChart(id) {
        if (!this.charts[id]) {
            const [chartIds] = await this._send('get', 'charts', [id]);
            if (!Array.isArray(chartIds)) {
                return Promise.reject('unexpected response received from iframe');
            }
            if (chartIds.length !== 1) {
                return Promise.reject('Invalid chart id: ' + id);
            }
            this.charts[id] = new DashboardChart(id, this);
        }
        return this.charts[id];
    }
    /**
     * @returns all charts on the dashboard
     */
    async getAllCharts() {
        const [chartIds] = await this._send('get', 'charts');
        if (!Array.isArray(chartIds)) {
            return Promise.reject('unexpected response received from iframe');
        }
        const charts = [];
        chartIds.forEach((id) => {
            if (!this.charts[id]) {
                this.charts[id] = new DashboardChart(id, this);
            }
            charts.push(this.charts[id]);
        });
        return charts;
    }
    _configureHost(hostBuilder) {
        return super
            ._configureHost(hostBuilder)
            .on('event', (event, payload, handlerIds) => {
            const chartId = payload.chartId;
            this.charts[chartId]._handleEvent(event, payload, handlerIds);
        });
    }
}
export default Dashboard;
