import {PharmacySelector} from "@components";
import {GetDosespotPharmaciesRes, useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Badge, Box, Button, IconButton, Page, Text, useToast} from "ferns-ui";
import React, {ReactElement, useState} from "react";

import {User} from "../store/modelTypes";

interface Props extends StaffStackScreenProps<"PharmacyScreen"> {}

const PatientPharmacy = ({
  pharmacy,
  onSetPrimary,
  onDelete,
}: {
  pharmacy: User["preferredPharmacies"][number];
  onSetPrimary: (pharmacy: User["preferredPharmacies"][number]) => Promise<void>;
  onDelete: () => Promise<void>;
}): ReactElement => {
  return (
    <Box
      border="default"
      color="base"
      direction="row"
      justifyContent="center"
      marginBottom={2}
      paddingX={4}
      paddingY={2}
      rounding="md"
    >
      <Box alignItems="center" direction="row" flex="grow">
        <Box alignItems="center" direction="column" marginRight={2} width="100%">
          <Box width="100%">
            <Box direction="row">
              <Text bold>{pharmacy.name}</Text>
              {pharmacy.primary && (
                <Box marginLeft={2}>
                  <Badge value="Primary" />
                </Box>
              )}
            </Box>
            <Text>{pharmacy.address.address1}</Text>
            <Text>
              {pharmacy.address.city}, {pharmacy.address.state} {pharmacy.address.zipcode}
            </Text>
            <Text>{pharmacy.phoneNumber}</Text>
          </Box>
        </Box>
      </Box>
      <Box alignContent="center" alignItems="center" direction="row" justifyContent="center">
        <Box>
          {!pharmacy.primary && (
            <Box marginRight={4}>
              <Button
                confirmationText="Are you sure you want to set this pharmacy as primary? This will automatically set the existing primary pharmacy as secondary."
                disabled={pharmacy.primary}
                text="Set Primary"
                variant="muted"
                withConfirmation
                onClick={() => onSetPrimary(pharmacy)}
              />
            </Box>
          )}
        </Box>
        <Box>
          <IconButton
            accessibilityLabel="delete"
            confirmationText={`Are you sure you want to remove ${pharmacy.name}?`}
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await onDelete();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const PharmacyScreen = ({route, navigation}: Props): ReactElement => {
  const userId = route.params.userId;
  const {data: userData} = useGetUsersByIdQuery(userId);
  const [updateUser] = usePatchUsersByIdMutation();
  const [addPharmacy, setAddPharmacy] = useState(false);
  const toast = useToast();

  const pharmacies = [...(userData?.preferredPharmacies ?? [])].sort((a, b) => {
    // Sort by primary first
    if (a.primary && !b.primary) return -1;
    if (!a.primary && b.primary) return 1;
    // Then sort alphabetically by name
    return a.name.localeCompare(b.name);
  });

  const onAddPharmacy = async (
    pharmacy: NonNullable<GetDosespotPharmaciesRes["Items"]>[number]
  ): Promise<void> => {
    // make sure the pharmacy is not already in the user's preferred pharmacies
    if (userData?.preferredPharmacies.some((p) => p.dosespotPharmacyId === pharmacy.PharmacyId)) {
      toast.show("Pharmacy already in preferred pharmacies");
      return;
    }
    // make sure all existing pharmacies are set to not primary
    const updatedPharmacies =
      userData?.preferredPharmacies && userData.preferredPharmacies?.length
        ? userData.preferredPharmacies.map((p) => ({...p, primary: false}))
        : [];
    updatedPharmacies.push({
      name: pharmacy.StoreName ?? "",
      address: {
        address1: pharmacy.Address1 ?? "",
        city: pharmacy.City ?? "",
        state: (pharmacy.State as any) ?? "", // we will handle the abbreviations DoseSpot provides us in the backend
        zipcode: pharmacy.ZipCode ?? "",
      },
      phoneNumber: pharmacy.PrimaryPhone ?? "",
      dosespotPharmacyId: pharmacy.PharmacyId,
      primary: true,
      notes: "",
    });
    await updateUser({id: userId, body: {preferredPharmacies: updatedPharmacies}});
    toast.show("Pharmacy added and set as primary");
    setAddPharmacy(false);
  };

  const onSetPrimary = async (pharmacy: User["preferredPharmacies"][number]): Promise<void> => {
    const updatedPharmacies = userData?.preferredPharmacies.map((p) => ({...p, primary: false}));
    if (!updatedPharmacies) {
      return;
    }
    const pharmacyToSetPrimary = updatedPharmacies.find(
      (p) => p.dosespotPharmacyId === pharmacy.dosespotPharmacyId
    );
    if (!pharmacyToSetPrimary) {
      return;
    }
    pharmacyToSetPrimary.primary = true;
    await updateUser({id: userId, body: {preferredPharmacies: updatedPharmacies}});
    toast.show(`${pharmacy.name} set as primary`);
  };

  const onDelete = async (pharmacyId: string): Promise<void> => {
    const updatedPharmacies = userData?.preferredPharmacies.filter((p) => p._id !== pharmacyId);
    await updateUser({id: userId, body: {preferredPharmacies: updatedPharmacies}});
    toast.show("Pharmacy deleted");
  };

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box marginTop={5}>
        {pharmacies.map((pharmacy) => (
          <PatientPharmacy
            key={pharmacy._id!}
            pharmacy={pharmacy}
            onDelete={() => onDelete(pharmacy._id!)}
            onSetPrimary={onSetPrimary}
          />
        ))}
        {!addPharmacy ? (
          <Button iconName="plus" text="Add Pharmacy" onClick={() => setAddPharmacy(true)} />
        ) : (
          <PharmacySelector onAddPharmacy={onAddPharmacy} />
        )}
      </Box>
    </Page>
  );
};
