import {DateTime} from "luxon";
import {useCallback, useEffect, useRef, useState} from "react";
import {io, Socket} from "socket.io-client";

export interface SocketConnection {
  isConnected: boolean;
  lastDisconnectedAt: string | null;
}

export interface UseSocketConnectionOptions {
  baseUrl: string;
  onConnect?: () => void;
  onDisconnect?: () => void;
  onConnectError?: (error: Error) => void;
  onReconnectFailed?: () => void;
  getAuthToken: () => Promise<string | null>;
  shouldConnect: boolean;
  showToast?: (message: string, options?: {persistent?: boolean; onDismiss?: () => void}) => string;
  hideToast?: (id: string) => void;
  captureEvent?: (eventName: string, data: Record<string, any>) => void;
}

export const useSocketConnection = ({
  baseUrl,
  onConnect,
  onDisconnect,
  onConnectError,
  onReconnectFailed,
  getAuthToken,
  shouldConnect,
  showToast,
  hideToast,
  captureEvent,
}: UseSocketConnectionOptions): {
  socket: Socket | null;
  isSocketConnected: SocketConnection;
} => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const isConnectedRef = useRef<SocketConnection>();
  const [isSocketConnected, setIsSocketConnected] = useState<SocketConnection>({
    isConnected: socket?.connected ?? false,
    lastDisconnectedAt: null,
  });
  const disconnectedToastId = useRef<string | null>(null);

  // Keep ref updated with latest socket connection state
  useEffect(() => {
    isConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  // Initialize socket connection
  useEffect(() => {
    const socketIo = io(baseUrl, {
      transports: ["websocket"],
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    setSocket(socketIo);

    return (): void => {
      socketIo.disconnect();
    };
  }, [baseUrl]);

  const hideDisconnectedToast = useCallback((): void => {
    if (disconnectedToastId.current && hideToast) {
      hideToast(disconnectedToastId.current);
      disconnectedToastId.current = null;
    }
  }, [hideToast]);

  // Connect the socket with the current auth token
  const connectSocket = useCallback(async (): Promise<void> => {
    const token = await getAuthToken();
    if (socket) {
      console.debug(`Socket connecting ${Boolean(token) ? "with" : "without"} token`);
      socket.auth = {token: `Bearer ${token}`};
      socket.connect();
    }
  }, [socket, getAuthToken]);

  // Connect/disconnect socket based on shouldConnect flag
  useEffect(() => {
    if (shouldConnect && !isSocketConnected.isConnected) {
      console.debug("Connecting socket");
      void connectSocket();
    }
    if (!shouldConnect && isSocketConnected.isConnected) {
      console.debug("Disconnecting socket");
      socket?.disconnect();
      setIsSocketConnected({
        isConnected: false,
        lastDisconnectedAt: null, // null because this was intentional
      });
    }
  }, [connectSocket, shouldConnect, isSocketConnected, socket]);

  // Show toast when disconnected
  useEffect(() => {
    if (!showToast || !hideToast || !shouldConnect) {
      return;
    }

    const checkShowToast = async (): Promise<void> => {
      const shouldShowDisconnectToast =
        !isSocketConnected.isConnected &&
        !disconnectedToastId.current &&
        isSocketConnected.lastDisconnectedAt &&
        DateTime.now().diff(DateTime.fromISO(isSocketConnected.lastDisconnectedAt), "seconds")
          .seconds > 9;

      if (shouldShowDisconnectToast) {
        hideDisconnectedToast(); // Clear any existing toasts first
        disconnectedToastId.current = showToast(
          "You have been disconnected. Attempting to reconnect...",
          {
            persistent: true,
            onDismiss: (): void => hideDisconnectedToast(),
          }
        );
      }
    };

    let intervalId: NodeJS.Timeout | null = null;

    // Check every second if we've reconnected
    const startCheckingConnection = (): void => {
      if (!isSocketConnected.isConnected && !intervalId) {
        intervalId = setInterval(async () => {
          await checkShowToast();
          if (isSocketConnected.isConnected && intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 1000);
      }
    };

    startCheckingConnection();

    return (): void => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isSocketConnected, hideDisconnectedToast, showToast, shouldConnect, hideToast]);

  // Set up basic socket event listeners
  useEffect(() => {
    if (!socket) return;

    const handleConnect = (): void => {
      console.debug("Socket connected");
      hideDisconnectedToast();

      // don't show toast if was disconnected and now connected within 10 seconds
      if (
        showToast &&
        isSocketConnected.lastDisconnectedAt &&
        DateTime.now().diff(DateTime.fromISO(isSocketConnected.lastDisconnectedAt), "seconds")
          .seconds > 10
      ) {
        showToast("You have been reconnected.");
      }

      setIsSocketConnected({
        isConnected: true,
        lastDisconnectedAt: null,
      });

      onConnect?.();
    };

    const handleDisconnect = async (): Promise<void> => {
      console.debug("Socket disconnected");
      setIsSocketConnected({
        isConnected: false,
        lastDisconnectedAt: DateTime.now().toISO(),
      });

      if (captureEvent) {
        captureEvent("WebSocket Disconnection", {
          time: DateTime.now().toISO(),
        });
      }

      await onDisconnect?.();
    };

    const handleConnectError = (error: Error): void => {
      console.error("Socket connection error:", error);
      if (captureEvent) {
        captureEvent("WebSocket Connection Error", {
          error: error.message,
          time: DateTime.now().toISO(),
        });
      }
      onConnectError?.(error);
    };

    const handleReconnectFailed = (): void => {
      console.error("Socket reconnection failed");
      // Force a new connection attempt
      socket.disconnect();
      setTimeout(() => {
        if (shouldConnect) {
          void connectSocket();
        }
      }, 5000);
      onReconnectFailed?.();
    };

    // Attach event listeners
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);
    socket.on("reconnect_failed", handleReconnectFailed);

    return (): void => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
      socket.off("reconnect_failed", handleReconnectFailed);
    };
  }, [
    socket,
    hideDisconnectedToast,
    showToast,
    isSocketConnected.lastDisconnectedAt,
    captureEvent,
    onConnect,
    onDisconnect,
    onConnectError,
    onReconnectFailed,
    shouldConnect,
    connectSocket,
  ]);

  return {socket, isSocketConnected};
};
