import {Box, CheckBox} from "ferns-ui";
import React from "react";
import {TouchableOpacity, View} from "react-native";

import {Bubble} from "./Bubble";
import {Day} from "./Day";
import {GiftedAvatar} from "./GiftedAvatar";
import {
  BubbleProps,
  DayProps,
  GiftedAvatarProps,
  IMessage,
  MessageProps,
  SystemMessageProps,
} from "./Models";
import {SystemMessage} from "./SystemMessage";
import {isSameUser} from "./utils";

export const Message = React.memo(
  <TMessage extends IMessage = IMessage>({
    containerStyle,
    currentMessage,
    forwardRef,
    inverted = true,
    isSelected,
    nextMessage,
    onMessageLayout,
    onSelectMessage,
    position = "left",
    previousMessage,
    renderAvatar,
    renderBubble,
    renderDay,
    renderSystemMessage,
    showMessageSelector,
    showUserAvatar = false,
    user,
    // AvatarProps
    textStyle,
    onPressAvatar,
    onLongPressAvatar,
    // Day Props
    textProps,
    timeFormat,
    dateFormat,
    wrapperStyle,
    // Bubble Props
    touchableProps,
    onPress,
    renderMessageImage,
    renderMessageVideo,
    renderMessageAudio,
    renderMessageText,
    renderUsername,
    renderTicks,
    renderTime,
    renderQuickReplies,
    onQuickReply,
    optionTitles,
    bottomContainerStyle,
    tickStyle,
    usernameStyle,
    containerToNextStyle,
    containerToPreviousStyle,
  }: MessageProps<TMessage>): React.ReactElement | null => {
    const doRenderDay = (): React.ReactElement | null => {
      const dayProps = {
        currentMessage,
        nextMessage,
        previousMessage,
        containerStyle,
        wrapperStyle,
        textStyle,
        textProps,
        dateFormat,
        inverted,
      } as DayProps<TMessage>;
      if (currentMessage?.createdAt) {
        if (renderDay) {
          return renderDay(dayProps);
        }
        return <Day {...dayProps} />;
      }
      return null;
    };

    const doRenderBubble = (): React.ReactElement | null => {
      const bubbleProps = {
        position,
        touchableProps,
        onPress,
        renderMessageImage,
        renderMessageVideo,
        renderMessageAudio,
        renderMessageText,
        renderUsername,
        renderTicks,
        renderTime,
        renderQuickReplies,
        onQuickReply,
        optionTitles,
        currentMessage,
        nextMessage,
        previousMessage,
        containerStyle,
        wrapperStyle,
        bottomContainerStyle,
        tickStyle,
        usernameStyle,
        containerToNextStyle,
        containerToPreviousStyle,
        timeFormat,
        dateFormat,
      } as BubbleProps<TMessage>;
      if (renderBubble) {
        return renderBubble(bubbleProps);
      }
      return <Bubble {...bubbleProps} />;
    };

    const doRenderSystemMessage = (): React.ReactElement | null => {
      const systemMessageProps = {
        currentMessage,
        containerStyle,
        textStyle,
        wrapperStyle,
      } as SystemMessageProps<TMessage>;
      if (renderSystemMessage) {
        return renderSystemMessage(systemMessageProps);
      }
      return <SystemMessage {...systemMessageProps} />;
    };

    const doRenderSelector = (): React.ReactElement | null => {
      if (!showMessageSelector) {
        return null;
      }
      return (
        <Box
          height="100%"
          justifyContent="end"
          marginLeft={position === "left" ? 0 : 2}
          marginRight={position === "left" ? 2 : 0}
        >
          <TouchableOpacity onPress={() => onSelectMessage?.(!isSelected)}>
            <CheckBox selected={Boolean(isSelected)} />
          </TouchableOpacity>
        </Box>
      );
    };

    const doRenderAvatar = (): React.ReactElement | null => {
      if (
        user?._id &&
        currentMessage?.user &&
        user._id === currentMessage.user._id &&
        !showUserAvatar
      ) {
        return null;
      }

      if (currentMessage?.user?.avatar === null) {
        return null;
      }

      const avatarProps = {
        currentMessage,
        nextMessage,
        onLongPressAvatar,
        onPressAvatar,
        position,
        previousMessage,
        user: currentMessage?.user,
      } as GiftedAvatarProps<TMessage>;

      if (renderAvatar) {
        return renderAvatar(avatarProps);
      }

      return <GiftedAvatar {...avatarProps} />;
    };

    if (!currentMessage) {
      return null;
    }

    const sameUser = isSameUser(currentMessage, nextMessage!);
    // Ignore that layout is supposed to be a private method.
    // There isn't a good public value for the width of the container.
    // @ts-ignore
    const viewWidth = forwardRef?.current?._layout?.width;
    return (
      <View style={{width: viewWidth}} onLayout={onMessageLayout}>
        {doRenderDay()}
        {currentMessage.system ? (
          doRenderSystemMessage()
        ) : (
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: position === "left" ? "flex-start" : "flex-end",
                marginLeft: position === "left" ? 8 : 0,
                marginRight: position === "left" ? 0 : 8,
                marginBottom: sameUser ? 2 : 10,
              },
              !inverted && {marginBottom: 2},
              containerStyle?.[position],
            ]}
          >
            {position === "left" ? doRenderSelector() : null}
            {position === "left" ? doRenderAvatar() : null}
            {doRenderBubble()}
            {position === "right" ? doRenderAvatar() : null}
            {position === "right" ? doRenderSelector() : null}
          </View>
        )}
      </View>
    );
  },
  (prevProps, nextProps) => {
    const next = nextProps.currentMessage!;
    const current = prevProps.currentMessage!;
    const {previousMessage, nextMessage} = prevProps;

    const shouldUpdate = prevProps?.shouldUpdateMessage?.(prevProps, nextProps) || false;

    const fieldsToCompare: [string, any, any][] = [
      ["sent", next.sent, current.sent],
      ["received", next.received, current.received],
      ["pending", next.pending, current.pending],
      ["createdAt", next.createdAt, current.createdAt],
      ["text", next.text, current.text],
      ["image", next.image, current.image],
      ["video", next.video, current.video],
      ["audio", next.audio, current.audio],
      ["avatar", next.user?.avatar, prevProps.currentMessage?.user?.avatar],
      ["currentMessage", current.deliveryStatuses?.[0]?.status, next.deliveryStatuses?.[0]?.status],
      ["prevmsg", previousMessage?._id, nextProps.previousMessage?._id],
      ["nextmsg", nextMessage?._id, nextProps.nextMessage?._id],
    ];

    let areEqual = !shouldUpdate;
    const diffs: [string, any, any][] = [];
    for (const [key, nextValue, currentValue] of fieldsToCompare) {
      if (nextValue !== currentValue) {
        areEqual = false;
        diffs.push([key, nextValue, currentValue]);
      }
    }

    // Uncomment this if you need to debug why the memoization here isn't working.
    // This would probably be useful to extract as a utility function for memoization.

    // if (!areEqual) {
    //   console.info(
    //     "MESSAGE DIFFS",
    //     current._id,
    //     diffs,
    //   "Should Update:", shouldUpdate,
    //     "Current Message:",
    //     current,
    //     "Next Message:",
    //     next
    //   );
    // }

    return areEqual;
  }
);

Message.displayName = "Message";
