import {
  baseWebsocketsUrl,
  getAuthToken,
  logSocket,
  useSelectCurrentUserId,
  useSocketConnection,
} from "@ferns-rtk";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  flourishApi,
  TagType,
  useAppDispatch,
  useGetUsersByIdQuery,
  useSelectInternalChatConversationId,
  useSelectInternalChatIsFocused,
} from "@store";
import {useToast} from "ferns-ui";
import React, {createContext, useContext, useEffect} from "react";
import {Socket} from "socket.io-client";

import {captureEvent, playSound, unloadSounds} from "../utils";

interface ISocketContext {
  socket: Socket | null;
}

// Create a context with a default value of null for the socket
export const SocketContext = createContext<ISocketContext>({socket: null});

// Custom hook to use the socket context
export const useSocket = (): ISocketContext => useContext(SocketContext);

interface SocketProviderProps {
  children: React.ReactElement;
}

// Provider Component
export const SocketProvider = ({children}: SocketProviderProps): React.ReactElement => {
  const selectedInternalChatConversation = useSelectInternalChatConversationId();
  const internalChatIsFocused = useSelectInternalChatIsFocused();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const currentUserId = useSelectCurrentUserId();
  const {data: user} = useGetUsersByIdQuery(currentUserId ?? skipToken);

  const handleDisconnect = async (): Promise<void> => {
    await unloadSounds();
  };

  const {socket} = useSocketConnection({
    baseUrl: baseWebsocketsUrl,
    getAuthToken,
    shouldConnect: Boolean(currentUserId && user?._id),
    showToast: toast.show,
    hideToast: toast.hide,
    captureEvent: (eventName: string, data: Record<string, any>) => {
      captureEvent(eventName, {
        ...data,
        userType: user?.type ?? "unknown",
      });
    },
    onDisconnect: handleDisconnect,
  });

  // Set up Flourish-specific socket event listeners
  useEffect(() => {
    if (!socket) return;

    const onSocketEvent = (data: any): void => {
      // Possible operation types include: "delete" | "invalidate" | "update" | "replace" |
      // "insert" | "drop" | "dropDatabase" | "rename"
      // Currently only invalidating in response to "insert" events
      const collection: any = data.collection;
      if (data.type === "insert") {
        logSocket(`Invalidating tag for insert: ${collection}`);
        dispatch(flourishApi.util.invalidateTags([collection]));
      }
      if (data.type === "update") {
        if (collection === "conversationstatuses") {
          // We need to invalidate this because we need conversations to refresh the unread count
          // when conversationStatus documents are updated on the backend to fix a bug where a
          // user's unread counts were out of sync across devices
          // TODO: This is a hacky bug fix for now - we should try to find a way to do this without
          // invalidating all conversations for all users
          logSocket(`Invalidating all conversations for conversationstatus update`);
          dispatch(flourishApi.util.invalidateTags(["conversations"]));
        } else if (collection === "conversations") {
          // We need to invalidate the whole conversations collection,
          // otherwise users added during an update will not see new conversations without
          // refreshing
          logSocket(`Invalidating all conversations for conversation update`);
          dispatch(flourishApi.util.invalidateTags([collection]));
        } else if (collection === "users") {
          logSocket(`Invalidating conversations and alert instances for user update`);
          dispatch(flourishApi.util.invalidateTags([{type: collection, id: data._id}]));
          // We need to invalidate conversations when updating a user so that changes (especially
          // online status) are reflected in the UI
          dispatch(flourishApi.util.invalidateTags(["conversations"]));
          // invalidate alertInstances when updating a user so that changes are reflected in the UI
          dispatch(flourishApi.util.invalidateTags(["alertinstances"]));
        } else if (collection === "notifications") {
          // We send notifications updates over websockets, no need to invalidates messages.
          logSocket(`SKIP Invalidating messages for notification update`);
        } else if (collection === "usersessions") {
          // Required so that the user's online status (dnd, last online,
          // etc) is updated in the UI across users across browser sessions
          logSocket(`Invalidating user sessions for user session update`);
          dispatch(
            flourishApi.util.updateQueryData("getUserSessions", {page: 1}, (draft) => {
              // Update the local RTK query cache with the updated user session data
              draft.data = draft.data?.map((doc) =>
                doc._id === data._id ? Object.assign(doc, data.updatedFields) : doc
              );
            })
          );
        } else {
          dispatch(flourishApi.util.invalidateTags([{type: collection, id: data._id}]));
        }
      }
    };

    const onInvalidateTagEvent = async (data: {collection: TagType}): Promise<void> => {
      logSocket(`Invalidating tag ${data.collection}`);
      dispatch(flourishApi.util.invalidateTags([data.collection]));
    };

    // When notifications for a message are updated (e.g.
    // twilio status changes from pending to delivered) we need to update the notification status in
    // the RTK query cache.
    const onMessageNotificationEvent = async (data: {
      messageId: string;
      conversationId: string;
      notificationId: string;
      notificationEventData: Record<string, any>;
    }): Promise<void> => {
      logSocket(
        `Notification event: ${data.messageId} ${data.notificationId} ${JSON.stringify(
          data.notificationEventData
        )}`
      );

      // Update the message notification status in the RTK query cache.
      // Note: this will only update the notification status for the first page of messages
      // Note: this will break if we change the initial message query for a conversation
      dispatch(
        flourishApi.util.updateQueryData(
          "getMessages",
          {conversationId: data.conversationId, page: 1},
          (draft) => {
            // Find all messages and update the notification status for the matching message
            draft.data = draft.data?.map((message) => {
              if (message._id === data.messageId) {
                return {
                  ...message,
                  notifications: message.notifications?.map((notification) =>
                    notification._id === data.notificationId
                      ? {...notification, ...data.notificationEventData}
                      : notification
                  ),
                };
              }
              return message;
            });
          }
        )
      );
    };

    // Attach Flourish-specific event listeners
    socket.on("changeEvent", onSocketEvent);
    socket.on(`invalidateTagEvent`, onInvalidateTagEvent);
    socket.on(`messageNotificationEvent`, onMessageNotificationEvent);

    // Cleanup function to remove event listeners
    return (): void => {
      socket.off("changeEvent", onSocketEvent);
      socket.off(`invalidateTagEvent`, onInvalidateTagEvent);
      socket.off(`messageNotificationEvent`, onMessageNotificationEvent);
    };
  }, [socket, dispatch]);

  // Handle notification events separately based on internal chat state
  useEffect(() => {
    if (!socket || !currentUserId) return;

    const onNotificationEvent = async (data: {
      conversationId?: string;
      sound: string;
    }): Promise<void> => {
      if (
        data.conversationId &&
        data.conversationId === selectedInternalChatConversation &&
        internalChatIsFocused
      ) {
        // Don't play a sound if the internal chat is focused and the notification is for the
        // conversation that is selected
        return;
      }
      await playSound(data.sound);
    };

    socket.on(`notificationEvent${currentUserId}`, onNotificationEvent);

    // Cleanup to avoid multiple listeners
    return (): void => {
      socket.off(`notificationEvent${currentUserId}`, onNotificationEvent);
    };
  }, [socket, selectedInternalChatConversation, internalChatIsFocused, currentUserId]);

  return <SocketContext.Provider value={{socket}}>{children}</SocketContext.Provider>;
};
