/* eslint-disable react/prop-types */
import {useSelectCurrentUserId} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  convertMessageToGifted,
  Message,
  useDeleteMessagesByIdMutation,
  useGetAvatarsQuery,
  useGetConversationsByIdQuery,
  useGetMessagesQuery,
  usePatchConversationsByIdMutation,
  usePostMessagesMutation,
  UserType,
  useUpdateLastReadMutation,
} from "@store";
import {isStaff, isSuperUser} from "@utils";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDebouncedCallback} from "use-debounce";

import {replaceMentionValues} from "../controlledMentions";
import {GiftedChat, IMessage} from "../giftedChat";
import {SocketContext} from "../SocketProvider";

interface Props {
  allowMentions?: boolean;
  conversationId: string;
  onSend?: (messages: IMessage[]) => void;
  allowDelete?: boolean;
  alwaysShowSend?: boolean;
  disableComposer?: boolean;
  renderUsernameOnMessage?: boolean;
  showSendAsSms?: boolean;
  // Allow the chatbox to be viewed as a different user. Useful for viewing someone else's
  // conversation.
  viewAsUserId?: string;
}

interface PropsWithMessages extends Props {
  messages: Message[];
  doLoadEarlier: () => void;
  isLoadingEarlier: boolean;
  showLoadEarlier: boolean;
  onSelectMessageIds?: (messages: string[]) => void;
  selectedMessageIds?: string[];
  showMessageSelector?: boolean;
}

export const ChatBoxComponent = ({
  allowMentions,
  messages,
  conversationId,
  onSend,
  showLoadEarlier,
  doLoadEarlier,
  isLoadingEarlier,
  allowDelete = false,
  alwaysShowSend = true,
  disableComposer = false,
  showSendAsSms = false,
  showMessageSelector = false,
  selectedMessageIds,
  onSelectMessageIds,
  viewAsUserId,
}: PropsWithMessages): ReactElement | null => {
  const {data: conversation} = useGetConversationsByIdQuery(conversationId);
  const [updateConversation] = usePatchConversationsByIdMutation();
  const [createMessage] = usePostMessagesMutation();
  const {data: avatarListResponse} = useGetAvatarsQuery({});
  const currentUserId = useSelectCurrentUserId();
  const avatarList = useMemo(() => avatarListResponse?.data ?? [], [avatarListResponse?.data]);
  const [sendAsSms, setSendAsSms] = useState(false);
  const profile = useReadProfile();
  const [updateLastRead] = useUpdateLastReadMutation();
  const [removeMessage, {isLoading: deleteLoading, error: deleteError}] =
    useDeleteMessagesByIdMutation();

  // Set sendAsSms once we fetch the conversation
  useEffect(() => {
    if (conversation) {
      setSendAsSms(Boolean(conversation.sendAsSms));
    }
  }, [conversation]);

  const conversationUserList = useMemo(() => {
    return (
      conversation?.users
        .filter((u) => u?.userId?._id !== profile?._id && Boolean(u?.userId?.name))
        .map((u) => ({
          name: u?.userId?.name || "",
          id: u?.userId?._id || "",
        })) ?? []
    );
  }, [conversation?.users, profile?._id]);

  const setSendAsSmsForConversation = async (value: boolean): Promise<void> => {
    if (conversation) {
      await updateConversation({id: conversationId, body: {sendAsSms: value}});
      setSendAsSms(value);
    }
  };

  const messageData = useMemo(
    () => messages.map((m) => convertMessageToGifted(m, avatarList)),
    [avatarList, messages]
  );

  const sendMessage = async (
    newMessages: IMessage[],
    options: {
      taggedUsers?: string[];
      isSms?: boolean;
      isRoomTag?: boolean;
    } = {}
  ): Promise<void> => {
    for (const message of newMessages) {
      // Unwrap so it'll throw an error and prevent the composer from clearing the text
      await createMessage({
        text: replaceMentionValues(message.text, ({name}) => `@${name}`),
        conversationId,
        messageUUID: message._id as string,
        taggedUsers: options?.taggedUsers?.map((tu) => ({userId: tu})) as any,
        roomTag: options?.isRoomTag,
      }).unwrap();
    }
    if (onSend) {
      onSend(newMessages);
    }
  };

  const onMarkUnread = async (message: IMessage): Promise<void> => {
    if (!profile || !message.conversationId) {
      return;
    }
    await updateLastRead({
      conversationId: message.conversationId,
      lastReadDateTime: DateTime.fromISO(message.createdAt).minus({seconds: 1}).toJSDate(),
    }).unwrap();
  };

  const partOfConversation = conversation?.users.find((u) => u?.userId?._id === profile?._id);
  const enableMarkUnread = useCallback(
    () => Boolean(profile && isStaff(profile?.type) && partOfConversation),
    [partOfConversation, profile]
  );
  const enableMessageDebugging = useMemo(
    (): boolean => Boolean(profile && isStaff(profile?.type)),
    [profile]
  );
  // Allow delete if message sent is from logged in user or user is a SuperUser
  // message.user._id is expected by gifted chat
  const enableRemoveMessage = useCallback(
    (message: IMessage): boolean => {
      if (!allowDelete || !isStaff(profile?.type)) {
        return false;
      }

      return Boolean(message.user._id === currentUserId || (profile && isSuperUser(profile)));
    },
    [allowDelete, currentUserId, profile]
  );

  const shouldShowDeliveryIcons = useCallback(
    (message: IMessage): boolean => {
      // Only show delivery icons for staff users for messages sent by staff.
      if (
        !message ||
        !isStaff(profile?.type) ||
        !isStaff(message.user?.type as UserType) ||
        !message.deliveryStatuses
      ) {
        return false;
      }
      return true;
    },
    [profile?.type]
  );

  const taggableUsers = useMemo(() => {
    return conversationUserList
      ? [
          {
            name: "Room",
            id: "",
          },
          ...conversationUserList,
        ]
      : [];
  }, [conversationUserList]);

  const onRemoveMessage = useCallback(
    async (msgId: string) => {
      await removeMessage(msgId);
    },
    [removeMessage]
  );

  const renderMessages = (): ReactElement => {
    return (
      <GiftedChat
        allowMentions={allowMentions}
        alwaysShowSend={alwaysShowSend}
        bottomOffset={0}
        conversationId={conversationId}
        disableComposer={disableComposer}
        enableMarkUnread={enableMarkUnread}
        enableMessageDebugging={enableMessageDebugging}
        enableRemoveMessage={enableRemoveMessage}
        infiniteScroll
        inverted
        isKeyboardInternallyHandled={false}
        isLoadingEarlier={isLoadingEarlier}
        keyboardShouldPersistTaps="never"
        loadEarlier={showLoadEarlier}
        messages={messageData}
        removeMessage={onRemoveMessage}
        removeMessageError={deleteError}
        removeMessageLoading={deleteLoading}
        selectedMessageIds={selectedMessageIds}
        sendAsSms={sendAsSms}
        setSendAsSmsForConversation={setSendAsSmsForConversation}
        shouldShowDeliveryIcons={shouldShowDeliveryIcons}
        showEmojiPicker
        showMessageSelector={showMessageSelector}
        showScrollToBottom
        showSendAsSms={showSendAsSms}
        showUserAvatar
        taggableUsers={taggableUsers}
        textInputProps={{
          blurOnSubmit: false,
          multiline: true,
        }}
        user={{
          _id: viewAsUserId ?? currentUserId ?? "",
          name: "A User",
        }}
        onLoadEarlier={doLoadEarlier}
        onMarkUnread={onMarkUnread}
        onSelectMessageIds={onSelectMessageIds}
        onSend={sendMessage}
      />
    );
  };

  if (!conversation) {
    return null;
  } else {
    return renderMessages();
  }
};

export const ChatBox = ({
  allowMentions = false,
  conversationId,
  onSend,
  allowDelete,
  alwaysShowSend,
  disableComposer,
  renderUsernameOnMessage,
  showSendAsSms,
}: Props): ReactElement => {
  const [localPage, setLocalPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [combinedData, setCombinedData] = useState<Message[]>([]);
  const paginateResponse = useGetMessagesQuery(
    hasMore
      ? {
          conversationId,
          page: localPage,
        }
      : skipToken
  );
  const {data: paginateData = [], page: remotePage = 1, more} = paginateResponse?.data || {};
  // Get the first page. Bonus, this will cache for pagination.
  const {data: messages} = useGetMessagesQuery({conversationId, page: 1});

  const {socket} = useContext(SocketContext);

  // Special socket for listening to specific chat events. Currently supports watching for deleted
  // messages. RTK Query + the way we do pagination makes it difficult to delete a single message,
  // so we watch sockets instead.
  // Watch the websocket connection for deleted messages
  useEffect(() => {
    const onDeleteEvent = (data: any): void => {
      if (data.type === "delete" && data.collection === "messages") {
        console.debug("Received message delete websocket event", data);
        setCombinedData((previousData) => {
          return previousData.filter((message) => message._id !== data._id);
        });
      }
    };

    socket?.on("changeEvent", onDeleteEvent);

    return (): void => {
      socket?.off("changeEvent", onDeleteEvent);
    };
  }, [setCombinedData, socket]);

  // Handles putting pagination requests into combined data.
  useEffect(
    () => {
      // Once the message fetch is completed, if there are no more results,
      // stop trying to fetch more messages.
      if (paginateResponse.isSuccess && more !== undefined) {
        setHasMore(more);
      }
      // Ensure paginated data comes before combined data in the uniqBy so we get the newer
      // paginated data, rather than the potentially stale data in combinedData.
      const uniqueMessages = uniqBy([...(paginateData ?? []), ...(combinedData ?? [])], "_id");
      if (
        uniqueMessages.length !== combinedData?.length ||
        uniqueMessages?.[0]?.updated !== combinedData?.[0]?.updated
      ) {
        setCombinedData((previousData) => {
          //
          return sortBy(
            uniqBy([...(paginateData ?? []), ...(previousData ?? [])], "_id"),
            "created"
          );
        });
      }
    },
    // Ignore react exhaustive deps warning because we don't want to update when the combinedData
    // are updated by the other useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginateData, localPage, remotePage, paginateResponse?.isSuccess, more]
  );

  // Handle when messages are updated and invalidated. Does not handle pagination.
  useEffect(
    () => {
      // TODO: Should we hook directly into websockets in conversations? Instead of totally relying
      // redux? We could potentially handle deleted better this way.

      // When new message data comes in, we always try to add it to the existing data.
      setCombinedData((previousData) => {
        return sortBy(
          // Filter out duplicated messages by _id. Make sure to put the new message data first,
          // so it overwrites previous data with the same _id.
          uniqBy([...(messages?.data ?? []), ...(previousData ?? [])], "_id"),
          "created"
        );
      });
    },
    // Ignore react exhaustive deps warning because we don't want to update when the combinedData
    // are updated by the other useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages?.data]
  );

  // We don't need to fetch results more than once per second.
  // This helps fix some jank where we try loading all the messages in a conversation when we scroll
  // to the top of the conversation.
  // TODO: Android infinite chat scrolling sometimes stays in place,  sometimes moves to the top,
  // sometimes looks janky.
  const readMore = useDebouncedCallback(
    (): void => {
      if (hasMore && paginateResponse.isSuccess && Number(remotePage) === localPage) {
        setLocalPage(Number(remotePage) + 1);
      }
    },
    500,
    // Immediately call the function at the end of 500ms, no matter how many times its invoked.
    {leading: false, trailing: true}
  );

  return (
    <ChatBoxComponent
      allowDelete={allowDelete}
      allowMentions={allowMentions}
      alwaysShowSend={alwaysShowSend}
      conversationId={conversationId}
      disableComposer={disableComposer}
      doLoadEarlier={readMore}
      isLoadingEarlier={paginateResponse.isFetching}
      messages={combinedData}
      renderUsernameOnMessage={renderUsernameOnMessage}
      showLoadEarlier={hasMore}
      showSendAsSms={showSendAsSms}
      onSend={onSend}
    />
  );
};
