import Chart from './chart';
import Dashboard from './dashboard';
import { getRealmUserToken } from './realm-auth';
/**
 * Creates an instance of the embedding SDK
 */
export default class EmbedSDK {
    /**
     * Accepts an optional {@link EmbedChartOptions} object to use as the
     * default options for any charts created using this SDK instance.
     *
     * ```js
     * const sdk = new EmbedSDK({
     *   baseUrl: "https://charts.mongodb.com",
     * })
     * ```
     */
    constructor(options) {
        this.defaultOptions = options;
    }
    /**
     * Creates a new {@link Chart} instance that allows you to
     * interact with and embed charts into your application
     */
    createChart(options) {
        return new Chart({ ...this.defaultOptions, ...options });
    }
    /**
     * Creates a new {@link Dashboard} instance that allows you
     * to embed a dashboard into your application
     */
    createDashboard(options) {
        return new Dashboard({ ...this.defaultOptions, ...options });
    }
}
export { getRealmUserToken };
