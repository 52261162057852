import {Box, Modal, Text, TextArea} from "ferns-ui";
import React, {useEffect, useState} from "react";

type FormConflictModalProps = {
  visible: boolean;
  onDismiss: () => void;
  outsideChanges: string;
  currentValue: string;
  primaryButtonOnClick: (resolvedValue: string) => void;
  fieldTitle: string;
  onBlur: (v: string) => void;
  onFocus: () => void;
};

export const FormConflictModal: React.FC<FormConflictModalProps> = ({
  visible,
  onDismiss,
  primaryButtonOnClick,
  fieldTitle,
  outsideChanges,
  currentValue,
  onBlur,
  onFocus,
}: FormConflictModalProps) => {
  const [resolvedValue, setResolvedValue] = useState(currentValue);

  // for form presence, trigger onFocus when the modal is visible,
  // and onBlur when it changes from visible to not visible
  useEffect(() => {
    if (visible) {
      onFocus();
      return (): void => {
        void onBlur(resolvedValue);
      };
    }
    // Only run when modal visibility changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      primaryButtonOnClick={() => primaryButtonOnClick(resolvedValue)}
      primaryButtonText="Resolve Changes"
      secondaryButtonOnClick={onDismiss}
      secondaryButtonText="Cancel"
      size="lg"
      title="Please update your changes to include outside updates since you started editing, if necessary."
      visible={visible}
      onDismiss={onDismiss}
    >
      <Box maxHeight={400} overflow="auto" width="100%">
        <Box direction="row" justifyContent="between" padding={2}>
          <Box color="neutralLight" padding={4} rounding="md" width="48%">
            <Text bold size="lg">
              Outside updates since you started editing
            </Text>
            <Text bold>{fieldTitle}</Text>
            <Box color="base" padding={2} rounding="md">
              <Text>{outsideChanges}</Text>
            </Box>
          </Box>

          <Box color="secondaryLight" padding={4} rounding="md" width="48%">
            <Text bold size="lg">
              Your changes (will update the patient when note is completed)
            </Text>
            <Text bold>{fieldTitle}</Text>
            <Box rounding="md">
              <TextArea
                blurOnSubmit={false}
                grow
                rows={5}
                value={resolvedValue}
                onChange={setResolvedValue}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
