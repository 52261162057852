export function Refreshable(Sender) {
    return class extends Sender {
        /**
         * Triggers a refresh of the chart or dashboard (if it has been embedded).
         *
         * @returns a promise that resolves once the chart or dashboard updated its data
         */
        async refresh() {
            await this._send('refresh');
        }
    };
}
