import {useLocalFormInstance, useSentryAndToast} from "@store";
import React, {useEffect, useMemo} from "react";

interface DuplicateAnswersToastProps {
  formInstanceId: string;
}

export const ToastDuplicateAnswersError: React.FC<DuplicateAnswersToastProps> = ({
  formInstanceId,
}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const sentryAndToast = useSentryAndToast();

  const duplicateAnswers = useMemo(() => {
    if (!formInstance?.answers || formInstance.answers?.length === 0) return null;

    const answers = formInstance.answers;
    const answerMap = new Map<string, number>();
    const duplicates: string[] = [];

    answers.forEach((answer) => {
      if (answer === null) return; // error captured in ToastNullAnswersError

      const count = answerMap.get(answer.questionId) || 0;
      answerMap.set(answer.questionId, count + 1);
      if (count + 1 > 1 && !duplicates.includes(answer.questionId)) {
        duplicates.push(answer.questionId);
      }
    });

    return duplicates;
  }, [formInstance?.answers]);

  // log error and show warning if duplicate answers are found
  useEffect(() => {
    if (duplicateAnswers && duplicateAnswers.length > 0) {
      duplicateAnswers.forEach((questionId) => {
        sentryAndToast(
          `Multiple answers found for question ${questionId} and form instance ${formInstance?._id}. Please contact support.`
        );
      });
      console.warn("Duplicate answers found:", duplicateAnswers);
    }
  }, [duplicateAnswers, formInstance?._id, sentryAndToast]);

  return null; // This component does not render any UI
};
