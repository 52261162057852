import { isEmpty, isEqual } from 'lodash';
let eventHandlerIndex = Date.now();
export function EventSource(Sender) {
    return class extends Sender {
        constructor() {
            super(...arguments);
            // needs to make this private instance variable public otherwise
            // TypeScript can't generate type definition file for this mixin
            // see https://github.com/microsoft/TypeScript/issues/17744
            /** @ignore */
            this._eventHandlers = {
                click: {},
                // refresh: {} To be added soon
            };
        }
        /**
         * Handle the event sent from embedded app.
         */
        _handleEvent(event, payload, handlerIds) {
            const handlers = this._eventHandlers[event];
            for (const id of handlerIds) {
                try {
                    // since communication between host and SDK is async,
                    // it's possible that some handlers have been removed;
                    // thus needs to check if handler still exists before calling
                    handlers[id]?.handle(payload);
                }
                catch (error) {
                    console.warn(`Error calling handler for event [${event}]: ${error}`);
                }
            }
        }
        /**
         * Sets an event listener
         * @param event - the event you are subscribing to
         * @param eventHandler - the callback to be executed when the event is triggered
         * @param options - optional options object, can be used to customise when handler is called
         */
        addEventListener(event, eventHandler, options) {
            const handlers = this._eventHandlers[event];
            if (!handlers) {
                throw new Error(`Not supported event: ${event}`);
            }
            const h = {
                handle: eventHandler,
                options: { includes: options?.includes },
            };
            if (h.options.includes?.every((f) => isEmpty(f))) {
                // eslint-disable-next-line no-console
                console.warn('Empty includes filters out all events. Event handler will never be called. Is this intended?');
            }
            // ignore if same handler and options have been added already
            if (!Object.keys(handlers).some((id) => isEqual(handlers[id], h))) {
                const handlerId = (++eventHandlerIndex).toString(36);
                handlers[handlerId] = h;
                return this._send('eventHandler', event, {
                    handlerId,
                    options: h.options,
                });
            }
            return Promise.resolve();
        }
        /**
         * Removes an event listener
         * @param event - the event you are unsubscribing from
         * @param eventHandler - the event listener function you are unsubscribing from
         * @param options - optional options object used when addEventListener
         */
        removeEventListener(event, eventHandler, options) {
            const handlers = this._eventHandlers[event];
            if (!handlers) {
                throw new Error(`Not supported event: ${event}`);
            }
            const h = {
                handle: eventHandler,
                options: { includes: options?.includes },
            };
            const handlerId = Object.keys(handlers).find((id) => isEqual(handlers[id], h));
            if (handlerId) {
                delete handlers[handlerId];
                return this._send('eventHandler', event, { handlerId });
            }
            return Promise.resolve();
        }
    };
}
