import {useCreateFormInstanceAddendumMutation, useLocalFormInstance} from "@store";
import {Box, Button, Heading, printDate, Text, TextArea, useToast} from "ferns-ui";
import React, {useCallback, useState} from "react";

interface FormInstanceAddendumProps {
  formInstanceId: string;
}

export const FormInstanceAddendum: React.FC<FormInstanceAddendumProps> = ({formInstanceId}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const [createFormInstanceAddendum] = useCreateFormInstanceAddendumMutation();
  const toast = useToast();
  const [addendumText, setAddendumText] = useState("");
  const [editing, setEditing] = useState(false);

  const handleAddendum = useCallback(async () => {
    await createFormInstanceAddendum({formInstanceId, text: addendumText})
      .unwrap()
      .then(() => {
        setAddendumText("");
        setEditing(false);
        toast.success("Created addendum");
      })
      .catch(toast.catch);
  }, [addendumText, createFormInstanceAddendum, formInstanceId, toast]);

  const handleCancel = useCallback(() => {
    setAddendumText("");
    setEditing(false);
  }, []);

  if (!formInstance) {
    return null;
  }

  if (formInstance.form.disableAddendums) {
    return null;
  }

  if (formInstance.status !== "Signed") {
    return null;
  }

  return (
    <Box gap={4} marginBottom={4}>
      <Box gap={4}>
        <Heading>Addendums</Heading>
        {formInstance.addendums.map((addendum, i) => (
          <React.Fragment key={`signed-${addendum._id}`}>
            <Text>
              {i + 1}: {addendum.text}
            </Text>
            <Text>
              Signed By: {addendum.signature?.signedById?.name ?? "Unknown"} on{" "}
              {printDate(addendum.signature?.signedDate ?? "Unknown")}
            </Text>
          </React.Fragment>
        ))}
      </Box>
      {Boolean(editing) ? (
        <Box gap={4}>
          <TextArea
            helperText="Addendums are only saved when tapping the Sign Addendum button"
            rows={8}
            title="New Addendum"
            value={addendumText}
            onChange={setAddendumText}
          />
          <Box direction="row" gap={4}>
            <Button text="Sign Addendum" onClick={handleAddendum} />
            <Button text="Cancel" variant="destructive" onClick={handleCancel} />
          </Box>
        </Box>
      ) : (
        <Box maxWidth={200}>
          <Button text="Add Addendum" onClick={() => setEditing(true)} />
        </Box>
      )}
    </Box>
  );
};
