import {Text} from "ferns-ui";
import * as React from "react";
import {Pressable, View} from "react-native";
import {useCallbackOne, useMemoOne} from "use-memo-one";

import {TEST_ID} from "./Constant";
import {IMessage, SendProps} from "./Models";

export const Send = <TMessage extends IMessage = IMessage>({
  text = "",
  containerStyle,
  children,
  label = "Send",
  alwaysShowSend = false,
  disabled = false,
  sendButtonProps,
  onSend = (): void => {},
}: SendProps<TMessage>): React.ReactElement | null => {
  const handleOnPress = useCallbackOne(() => {
    if (text && onSend) {
      onSend({text: text.trim()} as TMessage, true);
    }
  }, [text, onSend]);

  const showSend = useMemoOne(
    () => alwaysShowSend || (text && text.trim().length > 0),
    [alwaysShowSend, text]
  );

  if (!showSend) {
    return null;
  }

  return (
    <Pressable
      accessibilityLabel="send"
      accessible
      disabled={disabled}
      role="button"
      style={[
        {
          height: 44,
          justifyContent: "flex-end",
          opacity: disabled ? 0.3 : 1,
        },
        containerStyle,
      ]}
      testID={TEST_ID.SEND_TOUCHABLE}
      onPress={handleOnPress}
      {...sendButtonProps}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        {children || (
          <Text bold size="lg">
            {label}
          </Text>
        )}
      </View>
    </Pressable>
  );
};
