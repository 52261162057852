import { EJSON } from 'bson';
export const SDK_PROTOCOL_CHART = '3';
export const SDK_PROTOCOL_DASHBOARD = '3';
export const isPlainObject = (value) => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
};
/**
 * Retrieves embed options that are shared.
 *
 * Validates the values passed in as well.
 */
export const getSharedEmbedOptions = (options) => {
    const { background, baseUrl, autoRefresh, maxDataAge, width, height, theme, showAttribution, getUserToken, filter, preFilter, } = options;
    if (typeof baseUrl !== 'string' || baseUrl.length === 0) {
        throw new Error('Base URL must be a valid URL');
    }
    if (background !== undefined && typeof background !== 'string') {
        throw new Error('background must be a string if specified');
    }
    if (autoRefresh !== undefined && typeof autoRefresh !== 'boolean') {
        throw new Error('autoRefresh must be a boolean if specified');
    }
    if (maxDataAge !== undefined && typeof maxDataAge !== 'number') {
        throw new Error('maxDataAge must be a number if specified');
    }
    if (width !== undefined && !['number', 'string'].includes(typeof width)) {
        throw new Error('Width must be a string or number if specified');
    }
    if (height !== undefined && !['number', 'string'].includes(typeof height)) {
        throw new Error('Height must be a string or number if specified');
    }
    if (theme !== undefined && typeof theme !== 'string') {
        throw new Error('Theme must be a string if specified');
    }
    if (showAttribution !== undefined && typeof showAttribution !== 'boolean') {
        throw new Error('Attribution must be a boolean value if specified');
    }
    if (getUserToken !== undefined && typeof getUserToken !== 'function') {
        throw new Error('getUserToken must be a function');
    }
    if (filter !== undefined && !isPlainObject(filter)) {
        throw new Error('Filter must be an object if specified');
    }
    if (preFilter !== undefined && !isPlainObject(preFilter)) {
        throw new Error('Pre-filter must be an object if specified');
    }
    return {
        background,
        baseUrl,
        autoRefresh,
        maxDataAge,
        width,
        height,
        theme,
        showAttribution,
        getUserToken,
        filter,
        preFilter,
    };
};
export const getPathname = (url, pathname) => {
    return [
        url.pathname,
        url.pathname.slice(-1) === '/' ? '' : '/', // Add trailing slash if not there
        pathname,
    ].join('');
};
/**
 * Constructs the chart iframe URL from the baseUrl, chartId & tenantId
 */
export const getChartUrl = (options) => {
    try {
        const url = new URL(options.baseUrl);
        url.pathname = getPathname(url, 'embed/charts');
        url.search = `id=${options.chartId}&sdk=${SDK_PROTOCOL_CHART}`;
        if (options.autoRefresh === false) {
            url.search += `&autorefresh=false`;
        }
        else if (options.autoRefresh === undefined) {
            url.search += options.refreshInterval
                ? `&autorefresh=${options.refreshInterval}`
                : '';
        }
        if (options.maxDataAge !== undefined) {
            url.search += `&maxDataAge=${options.maxDataAge}`;
        }
        if (options.filter) {
            url.search += `&filter=${encodeURIComponent(EJSON.stringify(options.filter, { relaxed: false }))}`;
        }
        if (options.preFilter) {
            url.search += `&preFilter=${encodeURIComponent(EJSON.stringify(options.preFilter, { relaxed: false }))}`;
        }
        if (options.theme) {
            url.search += `&theme=${options.theme}`;
        }
        if (options.showAttribution === false) {
            url.search += `&attribution=false`;
        }
        return url.toString();
    }
    catch (e) {
        throw new Error('Base URL must be a valid URL');
    }
};
/**
 * Constructs the dashboard iframe URL from the baseUrl, dashboardId & tenantId
 */
export const getDashboardUrl = (options) => {
    try {
        const url = new URL(options.baseUrl);
        url.pathname = getPathname(url, 'embed/dashboards');
        url.search = `id=${options.dashboardId}&sdk=${SDK_PROTOCOL_DASHBOARD}`;
        if (options.autoRefresh === false) {
            url.search += `&autoRefresh=false`;
        }
        if (options.maxDataAge !== undefined) {
            url.search += `&maxDataAge=${options.maxDataAge}`;
        }
        if (options.showTitleAndDesc === true) {
            url.search += `&showTitleAndDesc=true`;
        }
        if (options.widthMode) {
            url.search += `&scalingWidth=${options.widthMode}`;
        }
        if (options.heightMode) {
            url.search += `&scalingHeight=${options.heightMode}`;
        }
        if (options.theme) {
            url.search += `&theme=${options.theme}`;
        }
        if (options.chartsBackground) {
            url.search += `&chartsBackground=${options.chartsBackground}`;
        }
        if (options.background) {
            url.search += `&background=${options.background}`;
        }
        if (options.showAttribution === false) {
            url.search += `&attribution=false`;
        }
        if (options.filter) {
            url.search += `&filter=${encodeURIComponent(EJSON.stringify(options.filter, { relaxed: false }))}`;
        }
        if (options.preFilter) {
            url.search += `&preFilter=${encodeURIComponent(EJSON.stringify(options.preFilter, { relaxed: false }))}`;
        }
        return url.toString();
    }
    catch (e) {
        throw new Error('Base URL must be a valid URL');
    }
};
/*
  Parses a CSS Measurement from an unknown value
  - if it's a string, we trust that it is well-formed
  - if it's a number, we assume the units are pixels
  - otherwise we return null
*/
export const parseCSSMeasurement = (value) => {
    if (typeof value === 'string')
        return value;
    if (typeof value === 'number')
        return `${value}px`;
    return null;
};
/**
 * Returns the background after validation checks
 * or default background based on theme if not set
 */
export const getBackground = (background, theme, lightBackground, darkBackground) => {
    if (typeof background === 'string' && background.length > 0)
        return background;
    if (theme === 'dark')
        return darkBackground;
    return lightBackground;
};
