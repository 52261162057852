import AsyncStorage from "@react-native-async-storage/async-storage";
import {IsMobileDevice, IsWeb} from "@utils";
import {MentionData} from "components/controlledMentions";
import {Badge, BooleanField, Box, IconButton, Modal, Tooltip} from "ferns-ui";
import isEqual from "lodash/isEqual";
import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Keyboard, StyleSheet, View, ViewStyle} from "react-native";
import EmojiSelector from "react-native-emoji-selector";

import {useSentryAndToast} from "../../store";
import {Actions} from "./Actions";
import Color from "./Color";
import {Composer, ComposerHandles} from "./Composer";
import {useChatContext} from "./GiftedChatContext";
import {IMessage, InputToolbarProps} from "./Models";
import {Send} from "./Send";

const TaggedUserBadges = ({
  taggedUsers,
  allowMentions = false,
}: {
  taggedUsers: Partial<MentionData>[];
  allowMentions?: boolean;
}): ReactElement | null => {
  if (!Boolean(allowMentions)) {
    return null;
  }

  return (
    <View
      style={{
        alignContent: "center",
        flexDirection: "row",
        height: "100%",
        marginLeft: 4,
        overflow: "hidden",
      }}
    >
      {taggedUsers?.map((u) => {
        return (
          <Box key={u.id} direction="row" padding={1}>
            <Badge value={u.name!} />
          </Box>
        );
      })}
    </View>
  );
};

const SMSToggle = ({
  onToggle,
  showSendAsSms = false,
  sendAsSms,
}: {
  onToggle?: (sendAsSms: boolean) => void;
  showSendAsSms?: boolean;
  sendAsSms?: boolean;
}): ReactElement | null => {
  if (!Boolean(showSendAsSms) || !onToggle) {
    return null;
  }
  return (
    <Box alignItems="center" direction="row" height="100%" justifyContent="center">
      <Box paddingX={3}>
        <BooleanField title="Send as SMS" value={Boolean(sendAsSms)} onChange={onToggle} />
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Color.neutralLight,
    backgroundColor: Color.white,
    bottom: 0,
    left: 0,
    right: 0,
  },
  primary: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export const InputToolbar = <TMessage extends IMessage>(
  props: InputToolbarProps<TMessage>
): React.ReactElement | null => {
  const {setSendAsSmsForConversation, sendAsSms, conversationId, showSendAsSms} = useChatContext();
  const sentryAndToast = useSentryAndToast();
  const composerRef = React.useRef<ComposerHandles>(null);
  const [position, setPosition] = useState("absolute");
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState<Partial<MentionData>[]>([]);
  const [isRoomTag, setIsRoomTag] = useState(false);
  const [text, setText] = useState("");
  const [sending, setSending] = useState(false);
  const [selection, setSelection] = useState<{start: number; end: number} | undefined>(undefined);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const {containerStyle, renderActions, onPressActionButton, disableComposer, ...rest} = props;

  const [storedText, setStoredText] = React.useState<string | null>(null);

  const doSetText = useCallback(
    (newText: string) => {
      setText(newText);
      if (conversationId) {
        AsyncStorage.setItem(`conversation-${conversationId}`, newText).catch((error) =>
          sentryAndToast(`Error saving message cache: ${error.message}`, error)
        );
      }
    },
    [conversationId, sentryAndToast]
  );

  // Load stored text on mount
  useEffect(() => {
    const getStoredMessage = async (): Promise<void> => {
      const storedMessage = await AsyncStorage.getItem(`conversation-${conversationId}`);
      if (storedMessage) {
        setText(storedMessage);
        setStoredText(storedMessage);
      }
    };
    // Only load once per conversation
    if (!storedText && conversationId) {
      void getStoredMessage();
    }
  }, [conversationId, storedText]);

  // When the keyboard shows and hies, set the position of the input toolbar to relative so that
  // it doesn't cover the keyboard.
  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener("keyboardWillShow", () =>
      setPosition("relative")
    );
    const keyboardWillHideListener = Keyboard.addListener("keyboardWillHide", () =>
      setPosition("absolute")
    );
    return (): void => {
      keyboardWillShowListener?.remove();
      keyboardWillHideListener?.remove();
    };
  }, []);

  const handleTaggedUserChange = (mentions: Partial<MentionData>[]): void => {
    // if room is removed from the mentions, set isRoomTag back to false
    if (!mentions.find((m) => m.name === "Room") && isRoomTag) {
      setIsRoomTag(false);
    }
    if (mentions?.find((m) => m.name === "Room")) {
      setIsRoomTag(true);
      // Remove the room user from the taggable users list before setting tagged users
      setTaggedUsers(props.taggableUsers?.filter((user) => user.name !== "Room") ?? []);
    } else if (!isEqual(mentions, taggedUsers)) {
      setTaggedUsers(mentions);
    }
  };

  const focusInput = (): void => {
    if (composerRef.current) {
      // It appears that a delay is needed for animations to finish before focusing.
      setTimeout(() => composerRef.current?.focusInput(), 700);
    }
  };

  const onFocusInput = (): void => {
    const newCursorPosition = (selection?.end ?? 0) + selectedEmoji.length;
    setSelection({start: newCursorPosition, end: newCursorPosition});
  };

  const renderEmojiButton = (): ReactElement | null => {
    if (IsMobileDevice || !props.showEmojiPicker || props.disableComposer) {
      return null;
    }
    return (
      <Box alignItems="center" direction="row" height="100%" justifyContent="center" padding={2}>
        <Tooltip text="Emoji">
          <IconButton
            accessibilityLabel="emoji"
            iconName="face-smile"
            onClick={() => setEmojiPickerVisible(true)}
          />
        </Tooltip>
      </Box>
    );
  };

  const renderEmojiPicker = (): ReactElement | null => {
    if (!props.showEmojiPicker) {
      return null;
    }
    return (
      <Modal
        title="Select Emoji"
        visible={emojiPickerVisible}
        onDismiss={() => setEmojiPickerVisible(false)}
      >
        <Box maxHeight={500} maxWidth={540} overflow="scroll">
          <EmojiSelector
            onEmojiSelected={(emoji: string) => {
              setEmojiPickerVisible(false);
              setSelectedEmoji(emoji);
              // TODO: handle selection so emoji can be placed at the correct spot when we have
              // also allow mentions
              // Until then, we'll put them at the end of the text.
              const end = selection?.end ?? text.length;

              doSetText(`${text.substring(0, end)}${emoji}${text.substring(end)}`);
              focusInput();
            }}
          />
        </Box>
      </Modal>
    );
  };

  const onChangedText = (changedText: string, mentions: Partial<MentionData>[]): void => {
    if (props.onTextChanged) {
      props.onTextChanged(changedText);
    }
    if (props.allowMentions) {
      handleTaggedUserChange(mentions);
    }
    doSetText(changedText);
  };

  // By default, send when enter is pressed. Can be overriden by textInputProps.onKeyPress
  const onKeyPress = (event: any): void => {
    if (IsWeb && event.code === "Enter") {
      // Send message on enter, unless shift is pressed for a new line or already sending.
      if (!event.shiftKey && text && !sending) {
        event.preventDefault();
        void onSend([{text: text.trim()} as TMessage]);
      }
    }
  };

  const onSend = useCallback(
    async (messages: TMessage[]) => {
      if (!props?.onSend) {
        console.warn("onSend prop is required to send messages");
        return;
      }
      const options = {
        taggedUsers: taggedUsers?.map((tu) => tu.id!).filter((id) => id !== ""),
        isRoomTag,
        sendAsSms,
      };
      setSending(true);
      try {
        await props.onSend(messages, options);
      } catch (error) {
        console.debug(`Error sending message: ${error}`);
        setSending(false);
        return;
      }
      setSending(false);
      doSetText("");
      setTaggedUsers([]);
      setIsRoomTag(false);
      if (conversationId) {
        AsyncStorage.removeItem(`conversation-${conversationId}`).catch((error) =>
          sentryAndToast(`Error remove message cache: ${error.message}`, error)
        );
      }
    },
    [conversationId, doSetText, isRoomTag, props, sendAsSms, taggedUsers, sentryAndToast]
  );

  return (
    <View style={[styles.container, {position}, containerStyle] as ViewStyle}>
      <View style={[styles.primary, props.primaryStyle]}>
        {renderActions?.(rest) || (onPressActionButton && <Actions {...rest} />)}
        {Boolean(!disableComposer) && (
          <Composer
            {...props}
            ref={composerRef}
            isRoomTag={isRoomTag}
            selection={selection}
            taggedUsers={taggedUsers}
            text={text}
            textInputProps={{onKeyPress, ...props?.textInputProps}}
            onFocusInput={onFocusInput}
            onSelectionChange={(event: {
              nativeEvent: {selection: {start: number; end: number}};
            }) => {
              setSelection({
                start: event.nativeEvent.selection.start,
                end: event.nativeEvent.selection.end,
              });
            }}
            onSend={onSend}
            onTextChanged={onChangedText}
          />
        )}

        <Send {...props} disabled={sending} text={text} onSend={onSend} />
      </View>
      <Box
        direction="row"
        justifyContent="start"
        marginLeft={IsMobileDevice ? 4 : 0}
        minHeight={48}
        paddingY={2}
        style={{position: "relative"}}
        width="100%"
        wrap
      >
        <TaggedUserBadges allowMentions={props.allowMentions} taggedUsers={taggedUsers} />
        <SMSToggle
          sendAsSms={sendAsSms}
          showSendAsSms={showSendAsSms}
          onToggle={setSendAsSmsForConversation}
        />

        <Box bottom position="absolute" right>
          {renderEmojiButton()}
        </Box>

        {renderEmojiPicker()}
      </Box>
    </View>
  );
};
