import {useAppSelector} from "@store";
import {useToast} from "ferns-ui";
import {useEffect, useRef} from "react";

export const SpamDetectionToast: React.FC<{}> = () => {
  const toast = useToast();
  const flourishApi = useAppSelector((state) => state.flourishApi);
  const shownMutationsRef = useRef<Set<string>>(new Set());
  const lastMutationsRef = useRef<Record<string, any>>({});

  // Show a toast for spam errors
  useEffect(() => {
    const currentMutations = flourishApi.mutations;

    // Check for new mutations with spam errors
    Object.entries(currentMutations).forEach(([mutationId, mutation]: [string, any]) => {
      const lastMutation = lastMutationsRef.current[mutationId];
      const isNewError = mutation?.error && !lastMutation?.error;

      if (
        isNewError &&
        mutation?.error?.data?.code === "spam" &&
        !shownMutationsRef.current.has(mutationId)
      ) {
        shownMutationsRef.current.add(mutationId);
        const toastId = toast.error(mutation.error.data.title, {
          size: "lg",
          persistent: true,
          onDismiss: () => {
            toast.hide(toastId);
            shownMutationsRef.current.delete(mutationId);
          },
          subtitle: mutation.error.data.detail,
        });
      }
    });

    // Update last mutations for next comparison
    lastMutationsRef.current = currentMutations;
  }, [flourishApi, toast]);

  return null;
};
