import {SHOULD_VALIDATE_CONFLICT_STATUSES} from "@constants";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isSupervisor as isSupervisorFn} from "@store";
import {DateTime} from "luxon";
import {useMemo} from "react";

import {RootState} from "../ferns-rtk/constants";
import {useAppSelector} from "./appState";
import {FormInstance, FormInstanceWithForm, User} from "./modelTypes";
import {flourishApi} from "./sdk";

interface LocalFormInstanceState {
  // Store local answers keyed by question ID. Use multiple questionIds so we can handle
  // showing local answers while we wait for the patch to complete.
  // patchedAt is used to track when the answer was last patched so we can clear it when the
  // patch and refetch is complete.
  localAnswers: Record<
    string,
    {
      instanceId: string;
      answers: string[];
      isEdited: boolean;
      followUpResponse?: string;
      patchedAt?: string;
      populatedFieldSnapshot?: {
        value?: string[];
        conflictResolved?: boolean;
      };
    }
  >;
  attendanceStatus?: string | undefined | null; // The attendance status for the form instance
  attended: {userId: string}[] | null; // The users that have attended the form instance
  routeReadOnly: boolean; // Flag for read-only mode from the route
  linkItemToggle: boolean; // Toggle states for linked items
  formInstanceId: string | null; // The formInstanceId that the local toggle is associated with
  // userPresence keeps tracking of which form question is currently focused and emits the
  // events to websockets when a question is focused or blurred, as well as while it is still
  // focused. It listens for websocket events from other users and prevents editing currently
  // focused questions by other users.
  userPresence: {
    [userId: string]: {
      [questionId: string]: {
        formInstanceId: string;
        timestamp: number;
      };
    };
  };
}

const initialState: LocalFormInstanceState = {
  localAnswers: {},
  attendanceStatus: null, // Default to no attendance status
  attended: null, // Default to no attended
  routeReadOnly: false, // Default to not read-only
  linkItemToggle: false, // No linked items toggled initially
  formInstanceId: null,
  userPresence: {},
};

const localFormInstanceSlice = createSlice({
  name: "localFormInstance",
  initialState,
  reducers: {
    // Action to set the current focused answer
    setCurrentFocusedAnswer: (
      state,
      action: PayloadAction<{
        instanceId: string;
        questionId: string;
        answers: string[];
        isEdited: boolean;
        followUpResponse?: string;
        patchedAt?: string;
        populatedFieldSnapshot?: {
          value?: string[];
          conflictResolved?: boolean;
        };
      } | null>
    ) => {
      if (!action.payload) {
        return;
      }
      const {
        questionId,
        answers,
        isEdited,
        followUpResponse,
        patchedAt,
        instanceId,
        populatedFieldSnapshot,
      } = action.payload;
      state.localAnswers[questionId] = {
        answers,
        isEdited,
        followUpResponse,
        instanceId,
        patchedAt,
        populatedFieldSnapshot,
      };
    },

    setAttendanceData: (
      state,
      action: PayloadAction<{
        attendanceStatus?: string | undefined;
        attended: {userId: any; _id?: string}[] | null;
      }>
    ) => {
      state.attendanceStatus = action.payload.attendanceStatus;
      state.attended = action.payload.attended;
    },

    setAttended: (state, action: PayloadAction<{userId: string}[]>) => {
      state.attended = action.payload;
    },

    // Action to clear an answer by questionId
    clearAnswerByQuestionId: (state, action: PayloadAction<string>) => {
      const questionId = action.payload;
      delete state.localAnswers[questionId];
    },

    // Action to toggle read-only state
    setRouteReadOnly: (state, action: PayloadAction<boolean>) => {
      state.routeReadOnly = action.payload;
    },

    // Action to set a linked item toggle (on/off)
    setLinkItemToggle: (state, action: PayloadAction<boolean>) => {
      state.linkItemToggle = action.payload;
    },

    setFormInstanceId: (state, action: PayloadAction<string>) => {
      state.formInstanceId = action.payload;
    },

    // Reset all local state back to the initial state
    clearLocalFormInstanceStore: (state) => {
      state.localAnswers = initialState.localAnswers;
      state.attendanceStatus = initialState.attendanceStatus;
      state.attended = initialState.attended;
      state.routeReadOnly = initialState.routeReadOnly;
      state.linkItemToggle = initialState.linkItemToggle;
      state.formInstanceId = initialState.formInstanceId;
    },

    updateUserPresence: (
      state,
      action: PayloadAction<{
        instanceId: string;
        userId: string;
        questionId: string;
        timestamp: number | null;
      }>
    ) => {
      const {instanceId, userId, questionId, timestamp} = action.payload;
      // Initialize the user and instance if they don't exist
      if (!state.userPresence) {
        state.userPresence = {};
      }
      if (!state.userPresence?.[userId]) {
        state.userPresence[userId] = {};
      }

      // Use timestamp === null to denote a value that should be removed.
      if (timestamp === null) {
        delete state.userPresence[userId][questionId];
      } else {
        state.userPresence[userId][questionId] = {timestamp, formInstanceId: instanceId};
      }
      // Clean up empty objects
      if (Object.keys(state.userPresence[userId]).length === 0) {
        delete state.userPresence[userId];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      flourishApi.endpoints.getFormInstancesById.matchFulfilled,
      (state, action) => {
        // When the formInstance is loaded set the linkItemToggle to the value of scheduleItemId
        if (state.formInstanceId !== action.payload._id) {
          state.linkItemToggle = Boolean(action.payload.scheduleItemId);
        }
        state.formInstanceId = action.payload._id;

        // Clear any local answers that have been patched remotely and are marked for ready to be
        // removed locally. This happens when the user blur's a question.
        Object.keys(state.localAnswers).forEach((questionId) => {
          const remoteAnswer = action.payload.answers.find((a) => a?.questionId === questionId);
          const localAnswer = state.localAnswers[questionId];
          if (
            localAnswer.patchedAt &&
            remoteAnswer?.updated &&
            DateTime.fromISO(localAnswer.patchedAt) < DateTime.fromISO(remoteAnswer.updated)
          ) {
            delete state.localAnswers[questionId];
          }
        });
      }
    );
  },
});

export const localFormInstanceReducer = localFormInstanceSlice.reducer;

const selectRemoteFormInstance =
  (instanceId: string) =>
  (state: RootState): FormInstance | undefined => {
    const selector = flourishApi.endpoints.getFormInstancesById.select(instanceId);
    return selector(state).data;
  };

export const useSelectRemoteFormInstance = (instanceId: string): FormInstance | undefined => {
  return useAppSelector((state: RootState) => selectRemoteFormInstance(instanceId)(state));
};

const selectIsReadOnly = (instanceId: string): ((state: RootState) => boolean) => {
  return createSelector(
    [
      selectRemoteFormInstance(instanceId),
      (state: RootState): User =>
        flourishApi.endpoints.getUsersById.select(state.auth.userId!)(state).data!,
      (state: RootState): boolean => state.localFormInstance.routeReadOnly,
    ],
    (formInstance, profile, routeReadOnly) => {
      const isSupervisor = isSupervisorFn(profile);

      return Boolean(
        routeReadOnly ||
          // The form is finalized and should no longer be edited
          formInstance?.status === "Signed" ||
          // The form is in a state that required supervisor edits. Only supervisors can edit
          (formInstance?.status === "Requires Supervisor Action" && !isSupervisor) ||
          // A user has been archived or deleted
          !formInstance?.userId // is null
      );
    }
  );
};

export const useSelectUserPresence = (): LocalFormInstanceState["userPresence"] => {
  return useAppSelector((state) => {
    return state.localFormInstance.userPresence;
  });
};

export const useFormInstanceIsReadOnly = (instanceId: string): boolean => {
  return useAppSelector((state: RootState): boolean => {
    const isReadOnlySelector = selectIsReadOnly(instanceId);
    return isReadOnlySelector(state);
  });
};

export const useFormInstanceLinkToggle = (): boolean => {
  return useAppSelector((state) => {
    return state.localFormInstance.linkItemToggle;
  });
};

export const useSelectIsQuestionDisabled = (instanceId: string, questionId: string): boolean => {
  return useAppSelector((state: RootState): boolean => {
    const isReadOnly = selectIsReadOnly(instanceId)(state);
    const otherUsers = selectOtherUsersEditingFormQuestion(questionId)(state);
    const isOtherUserEditing = otherUsers.some((userId) => userId !== state.auth.userId);
    const formInstance = flourishApi.endpoints.getFormInstancesById.select(instanceId)(state).data;
    const profile = flourishApi.endpoints.getUsersById.select(state.auth.userId!)(state).data;
    const question = (formInstance as FormInstanceWithForm)?.form?.questions?.find(
      (q) => q._id === questionId
    );

    if (isReadOnly || isOtherUserEditing) {
      return true;
    }
    // only allow supervisors to answer supervisor only questions
    if (question?.isSupervisorOnly && !isSupervisorFn(profile)) {
      return true;
    }
    // indicates the formInstance has been completed and signed by the created by user
    // and manager should not be able to edit their answers.
    // managers should only be able to answer questions specific to themself at this point
    if (formInstance?.status === "Requires Supervisor Action" && !question?.isSupervisorOnly) {
      return true;
    }

    // disable editing for populated fields that will not undergo further validation based on status
    if (
      question?.populateFields?.length &&
      (!formInstance?.status || !SHOULD_VALIDATE_CONFLICT_STATUSES.includes(formInstance?.status))
    ) {
      return true;
    }
    return false;
  });
};

const selectLocalFormInstance = createSelector(
  [
    (state: RootState, instanceId: string): FormInstanceWithForm | undefined =>
      flourishApi.endpoints.getFormInstancesById.select(instanceId)(state)?.data as
        | FormInstanceWithForm
        | undefined,
    (state: RootState): LocalFormInstanceState["localAnswers"] =>
      state.localFormInstance.localAnswers,
    (state: RootState): LocalFormInstanceState["attendanceStatus"] =>
      state.localFormInstance.attendanceStatus,
    (state: RootState): LocalFormInstanceState["attended"] => state.localFormInstance.attended,
  ],
  (
    formInstanceResult,
    currentFocusedAnswer,
    localAttendanceStatus,
    localAttended
  ): FormInstanceWithForm | null => {
    if (!formInstanceResult) {
      return null;
    }

    const formInstance = {...formInstanceResult};

    if (currentFocusedAnswer) {
      formInstance.answers = formInstance.answers.map((answer) => {
        const focusedAnswer = currentFocusedAnswer[answer?.questionId];
        // If the question has not be edited by the user, update the answer with the remote answer.
        if (focusedAnswer && focusedAnswer.isEdited) {
          return {
            ...answer,
            answers: focusedAnswer.answers,
            followUpResponse: focusedAnswer.followUpResponse,
          };
        }
        return answer;
      });
    }
    // keep transition state selections of attendance status smooth through the lifecycle of the
    // form instance
    if (localAttendanceStatus && localAttendanceStatus !== formInstance.attendanceStatus) {
      formInstance.attendanceStatus = localAttendanceStatus;
    }
    if (localAttended && localAttended !== formInstance.attended) {
      formInstance.attended = localAttended;
    }

    return formInstance as FormInstanceWithForm;
  }
);

// Select the form instance with the local answers added in.
export const useLocalFormInstance = (instanceId: string): FormInstanceWithForm | null => {
  return useAppSelector((state: RootState) =>
    selectLocalFormInstance(state, instanceId)
  ) as FormInstanceWithForm;
};

const selectOtherUsersEditingFormQuestion = (
  questionId: string | undefined
): ((state: RootState) => string[]) => {
  return createSelector(
    [
      (state: RootState): LocalFormInstanceState["userPresence"] =>
        state.localFormInstance.userPresence,
      (state: RootState): string | null => state.auth.userId,
    ],
    (userPresence, profileId) => {
      if (!questionId) return [];

      const now = DateTime.now();
      return Object.entries(userPresence ?? {})
        .filter(([userId, userData]) => {
          if (userId === profileId) {
            return false;
          }
          const questionData = userData[questionId];
          if (!questionData) {
            return false;
          }
          return now.diff(DateTime.fromMillis(questionData.timestamp), "seconds").seconds < 5;
        })
        .map(([userId]) => userId);
    }
  );
};

export const useOtherUsersEditingFormQuestion = (questionId: string | undefined): string[] => {
  return useAppSelector(
    // Needs memo since we're returning an array.
    useMemo(() => selectOtherUsersEditingFormQuestion(questionId), [questionId])
  );
};

export const {
  setCurrentFocusedAnswer,
  setAttendanceData,
  setAttended,
  clearAnswerByQuestionId,
  setRouteReadOnly,
  setLinkItemToggle,
  clearLocalFormInstanceStore,
  updateUserPresence,
} = localFormInstanceSlice.actions;
