import {skipToken} from "@reduxjs/toolkit/query";
import {
  GetDosespotPharmaciesArgs,
  GetDosespotPharmaciesRes,
  useGetDosespotPharmaciesQuery,
} from "@store";
import {US_STATES_ABBREVIATIONS} from "@utils";
import {Box, Button, PhoneNumberField, SelectField, Text, TextField} from "ferns-ui";
import React, {FunctionComponent, useState} from "react";

export const PharmacySelector: FunctionComponent<{
  onAddPharmacy: (pharmacy: NonNullable<GetDosespotPharmaciesRes["Items"]>[number]) => void;
}> = ({onAddPharmacy}) => {
  const [shouldSearchDoseSpot, setShouldSearchDoseSpot] = useState(false);
  const [query, setQuery] = useState<GetDosespotPharmaciesArgs>({
    page: 1,
    pharmacyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneOrFax: "",
  });
  const {data: doseSpotPharmacies, isLoading: isSearchingDoseSpotPharmacies} =
    useGetDosespotPharmaciesQuery(shouldSearchDoseSpot ? query : skipToken);

  const searchPharmacies = (q: GetDosespotPharmaciesArgs): void => {
    // Ensure all fields exist with at least empty strings
    const validQuery = {
      page: q.page,
      pharmacyName: q.pharmacyName || "",
      address: q.address || "",
      city: q.city || "",
      state: q.state || "",
      zip: q.zip || "",
      phoneOrFax: q.phoneOrFax || "",
    };
    setQuery(validQuery);
    setShouldSearchDoseSpot(true);
  };

  return (
    <Box border="default" padding={4} rounding="md" width="100%">
      <Text bold>Pharmacy Search</Text>
      {!shouldSearchDoseSpot && !isSearchingDoseSpotPharmacies && (
        <PharmacySearch query={query} setQuery={setQuery} setSearchQuery={searchPharmacies} />
      )}
      {shouldSearchDoseSpot && !isSearchingDoseSpotPharmacies && (
        <Box direction="row">
          <Button text="New Search" onClick={() => setShouldSearchDoseSpot(false)} />
        </Box>
      )}
      {isSearchingDoseSpotPharmacies && <Text>Searching...</Text>}
      {doseSpotPharmacies && doseSpotPharmacies.Items && (
        <PharmacyResults pharmacies={doseSpotPharmacies} onSelect={onAddPharmacy} />
      )}
    </Box>
  );
};

const PharmacySearch: FunctionComponent<{
  setSearchQuery: (query: GetDosespotPharmaciesArgs) => void;
  query: GetDosespotPharmaciesArgs;
  setQuery: (query: GetDosespotPharmaciesArgs) => void;
}> = ({setSearchQuery, query, setQuery}) => {
  return (
    <Box direction="column" paddingX={4} paddingY={2} scroll width="100%">
      <TextField
        title="Name"
        value={query.pharmacyName}
        onChange={(value: any): void => {
          setQuery({...query, pharmacyName: value});
        }}
        onEnter={() => setSearchQuery(query)}
      />
      <TextField
        title="Address"
        value={query.address}
        onChange={(value: any): void => {
          setQuery({...query, address: value});
        }}
        onEnter={() => setSearchQuery(query)}
      />
      <TextField
        title="City"
        value={query.city}
        onChange={(value: any): void => {
          setQuery({...query, city: value});
        }}
        onEnter={() => setSearchQuery(query)}
      />
      <SelectField
        options={US_STATES_ABBREVIATIONS.map((code) => ({
          label: code,
          value: code,
        }))}
        title="State"
        value={query.state}
        onChange={(value: any): void => {
          setQuery({...query, state: value});
        }}
      />
      <TextField
        title="Zip"
        value={query.zip}
        onChange={(value: any): void => {
          setQuery({...query, zip: value});
        }}
        onEnter={() => setSearchQuery(query)}
      />
      <PhoneNumberField
        title="Phone Number"
        value={query.phoneOrFax}
        onChange={(value: any): void => {
          setQuery({...query, phoneOrFax: value});
        }}
        onEnter={() => setSearchQuery(query)}
      />
      <Box direction="row" marginTop={4}>
        <Button text="Search" onClick={() => setSearchQuery(query)} />
      </Box>
    </Box>
  );
};

const PharmacyResults: FunctionComponent<{
  pharmacies: GetDosespotPharmaciesRes;
  onSelect: (pharmacy: NonNullable<GetDosespotPharmaciesRes["Items"]>[number]) => void;
}> = ({pharmacies, onSelect}) => {
  if (!pharmacies.Items) {
    return null;
  }

  return (
    <Box>
      {pharmacies.Items.map((pharmacy) => (
        <Box
          key={pharmacy.PharmacyId}
          accessibilityHint="Add this pharmacy to your preferred pharmacies"
          accessibilityLabel={pharmacy.StoreName ?? "Pharmacy Name"}
          borderBottom="default"
          direction="row"
          justifyContent="between"
          paddingY={2}
          width="100%"
        >
          <Box flex="grow">
            <Text>{pharmacy.StoreName}</Text>
            <Text>{pharmacy.Address1}</Text>
            <Text>
              {pharmacy.City}, {pharmacy.State} {pharmacy.ZipCode}
            </Text>
            <Text>{pharmacy.PrimaryPhone}</Text>
          </Box>
          <Box alignItems="end" flex="grow" justifyContent="center">
            <Button text="Select" onClick={() => onSelect(pharmacy)} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
