import { EventSource } from './mixins/event-source';
import { Refreshable } from './mixins/refreshable';
import { CommonChart } from './mixins/common-chart';
import { Filterable } from './mixins/filterable';
class DashboardChartEventSender {
    constructor(chartId, dashboard) {
        this.chartId = chartId;
        this.dashboard = dashboard;
    }
    /**
     * Send message to embedded app via dashboard.
     */
    _send(msgName, ...payload) {
        return this.dashboard._send(msgName, ...payload, this.chartId);
    }
}
export default class DashboardChart extends CommonChart(Filterable(Refreshable(EventSource(DashboardChartEventSender)))) {
}
