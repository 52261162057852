import { EJSON } from 'bson';
import { isPlainObject } from '../utils';
export function Filterable(Sender) {
    return class extends Sender {
        /**
         * Sets a filter which will be applied as the first stage on the Data Source.
         * The chart entity can be an embedded chart, embedded dashboard chart or embedded dashboard.
         * If a Charts View is defined in the UI, then this filter will be applied after the defined pipeline.
         *
         * This expects an object that contains a valid [query operators](https://www.mongodb.com/docs/manual/reference/operator/query/#query-selectors).
         * Any fields referenced in this filter are expected to be allowed for filtering in the "Embed Chart" dialog or "Embed Dashboard" dialog for each chart/dashboard you wish to filter on.
         *
         * If filtering is required on a dynamic field defined via a Lookup, Calculated or Query Bar, then `setFilter` is the appropriate method.
         *
         * @param value The filter object to be applied after the defined Charts View in the UI
         * @returns a promise that resolves once the filter is saved and the component rerendered
         */
        async setPreFilter(value) {
            if (!isPlainObject(value)) {
                return Promise.reject('filter property value should be an object');
            }
            await this._send('set', 'preFilter', EJSON.stringify(value, { relaxed: false }));
        }
        /**
         * Returns the current pre-filter applied to the embedded chart
         * The filter value is taken from the state of the chart entity in the Charts app component.
         *
         * @returns a promise that resolves once the filter is taken from the Charts state
         */
        async getPreFilter() {
            const [result] = await this._send('get', 'preFilter');
            return isPlainObject(result)
                ? result
                : Promise.reject('unexpected response received from iframe');
        }
        /**
         * Sets a filter to the state of the chart/dashboard entity in the Charts app component.
         * The chart entity can be an embedded chart, embedded dashboard chart or embedded dashboard.
         * The filter gets applied to the embedded chart/dashboard.
         *
         * This expects an object that contains a valid [query operators](https://www.mongodb.com/docs/manual/reference/operator/query/#query-selectors).
         * Any fields referenced in this filter are expected to be allowed for filtering in the "Embed Chart" dialog or "Embed Dashboard" dialog for each chart/dashboard you wish to filter on.
         *
         * @param value The filter object to be applied to the chart/dashboard
         * @returns a promise that resolves once the filter is saved and the component rerendered
         */
        async setFilter(value) {
            if (!isPlainObject(value)) {
                return Promise.reject('filter property value should be an object');
            }
            await this._send('set', 'filter', EJSON.stringify(value, { relaxed: false }));
        }
        /**
         * Returns the current filter applied to the embedded chart
         * The filter value is taken from the state of the chart entity in the Charts app component.
         *
         * @returns a promise that resolves once the filter is taken from the Charts state
         */
        async getFilter() {
            const [result] = await this._send('get', 'filter');
            return isPlainObject(result)
                ? result
                : Promise.reject('unexpected response received from iframe');
        }
    };
}
