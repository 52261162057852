// Disabled temporarily to fix: https://github.com/mongodb-js/charts-embed-sdk/issues/14
// Until we come up with a better way to have strong typing for the Stitch client, while
// also not breaking normal TSC compiles of the SDK
// import type { StitchAppClient } from 'mongodb-stitch-browser-sdk';
const isJWTExpired = (jwt) => {
    try {
        const [header, payload, signature] = jwt.split('.');
        const { exp } = JSON.parse(atob(payload));
        // Check the current time against the expiry (minus 5 minutes) in the token
        return Date.now() / 1000 >= exp - 300;
    }
    catch (e) {
        throw new Error('Not a vaid JWT token. Is the StitchClient/RealmClient configured correctly?');
    }
};
/**
 * A helper utility to support using [Realm Authentication](https://www.mongodb.com/docs/realm/) with MongoDB Charts with two npm packages:
 *
 * Using "mongodb-stitch-browser-sdk"
 *
 * ```js
 * const client = Stitch.initializeDefaultAppClient('<your-client-app-id>');
 * client.auth.loginWithCredential(...)
 *
 * const sdk = new ChartsEmbedSDK({
 *   getUserToken: () => getRealmUserToken(client)
 * })
 * ```
 *
 * or using "realm-web"
 *
 * ```js
 * const client = new Realm.App({id: '<your-client-app-id>'});
 * client.logIn(...)
 *
 * const sdk = new ChartsEmbedSDK({
 *   getUserToken: () => getRealmUserToken(client)
 * })
 * ```
 *
 */
export async function getRealmUserToken(appClient) {
    // Authentication with Realm was first implemented to work for apps using mongodb-stitch-browser-sdk.
    // Later, it got deprecated and a new sdk was published by the Realm team - realm-web.
    // Here, we are checking which Stitch/Realm sdk is used and use that path to pass the auth token successfully
    let token;
    // if the user is using the "mongodb-stitch-browser-sdk"
    if ('auth' in appClient) {
        const stitchClient = appClient;
        if (!stitchClient.auth.authInfo) {
            throw new Error('Unfamiliar Stitch client version');
        }
        if (!stitchClient.auth.isLoggedIn) {
            throw new Error('Could not find a logged-in StitchUser. Is the StitchClient configured correctly?');
        }
        if (!stitchClient.auth.authInfo.accessToken) {
            throw new Error('Could not find a valid JWT. Is the StitchClient configured correctly?');
        }
        if (isJWTExpired(stitchClient.auth.authInfo.accessToken)) {
            // Attempt to refresh token using progression from public -> private apis
            if (stitchClient.auth.refreshCustomData) {
                await stitchClient.auth.refreshCustomData(); // supported from 4.8.0
            }
            else if (stitchClient.auth.refreshAccessToken) {
                await stitchClient.auth.refreshAccessToken(); // supported from 4.0.0
            }
            else {
                throw new Error('Could not refresh token. Unfamiliar Stitch client version');
            }
        }
        token = stitchClient.auth.authInfo.accessToken;
    }
    // if the user is using realm-web
    else if ('authenticator' in appClient) {
        const realmClient = appClient;
        if (!realmClient.currentUser) {
            throw new Error('Unfamiliar Realm client version');
        }
        if (!realmClient.currentUser.isLoggedIn) {
            throw new Error('Could not find a logged-in RealmUser. Is the RealmClient configured correctly?');
        }
        if (!realmClient.currentUser.accessToken) {
            throw new Error('Could not find a valid JWT. Is the RealmClient configured correctly?');
        }
        if (isJWTExpired(realmClient.currentUser.accessToken)) {
            // Attempt to refresh token
            if (realmClient.currentUser.refreshCustomData) {
                await realmClient.currentUser.refreshCustomData();
            }
            else {
                throw new Error('Could not refresh token. Unfamiliar Realm client version');
            }
        }
        token = realmClient.currentUser.accessToken;
    }
    else {
        throw new Error('Unfamiliar Stitch or Realm client version');
    }
    return token;
}
