import {useReadProfile} from "@hooks";
import * as Sentry from "@sentry/react-native";
import {useGetScheduleItemsQuery, useGetUsersByIdQuery} from "@store";
import {isStaff, UserTypes} from "@utils";
import {Box, SegmentedControl, Text} from "ferns-ui";
import React, {ReactElement, useState} from "react";

import {EventSchedulerButton} from "./EventSchedulerButton";
import {FallbackScheduleItem, ScheduleItem} from "./UserScheduleItem";

interface UserScheduleProps {
  userId: string;
  type: UserTypes.Patient | UserTypes.Staff;
}

export const SchedulePane = ({userId, type}: UserScheduleProps): ReactElement | null => {
  const profile = useReadProfile();
  const [timeframe, setTimeframe] = useState<"Future" | "Past">("Future");
  const {data: selectedUser} = useGetUsersByIdQuery(userId);
  const {data: scheduleItems} = useGetScheduleItemsQuery(
    {
      ...(isStaff(type) ? {"staff.userId": userId} : {"users.userId": userId}),
      timeframe,
      sort: timeframe === "Future" ? "startDatetime" : "-startDatetime",
    },
    // Need to refetch when the timeframe changes
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (!profile) {
    return null;
  }

  return (
    <Box gap={4}>
      <EventSchedulerButton selectedUser={selectedUser!} />
      <Box maxWidth={200} paddingY={4}>
        <SegmentedControl
          items={["Future", "Past"]}
          selectedIndex={timeframe === "Future" ? 0 : 1}
          onChange={(index) => setTimeframe(index === 0 ? "Future" : "Past")}
        />
      </Box>
      <Box>
        {(scheduleItems?.data ?? []).map((s) => (
          <Sentry.ErrorBoundary key={s._id} fallback={<FallbackScheduleItem />}>
            <ScheduleItem
              isPast={Boolean(timeframe === "Past")}
              scheduleItem={s}
              type={type}
              userId={userId}
            />
          </Sentry.ErrorBoundary>
        ))}
        {scheduleItems?.data?.length === 0 && (
          <Box padding={4}>
            <Text>No {timeframe.toLowerCase()} schedule items found.</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
