import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {User} from "@store";
import {StaffStackParamList} from "@types";
import {Box, Button, SelectField} from "ferns-ui";
import React, {useState} from "react";

import {APPOINTMENT_CONFIG, AppointmentType, DEFAULT_APPT_OPTIONS} from "../constants";
import {hasFeatureFlag, isStaff, IsWeb} from "../utils";

interface EventSchedulerButtonProps {
  selectedUser: User;
}

export const EventSchedulerButton: React.FC<EventSchedulerButtonProps> = ({selectedUser}) => {
  const profile = useReadProfile();
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  const [type, setType] = useState<AppointmentType | undefined>(undefined);

  // Only support the appointment scheduler on web for now.
  if (!IsWeb || !profile) {
    return null;
  }

  if (hasFeatureFlag(profile, "appointments")) {
    return (
      <Box direction="row" gap={4}>
        <SelectField
          options={DEFAULT_APPT_OPTIONS}
          requireValue={false}
          value={type}
          onChange={(t) => setType(t as AppointmentType)}
        />
        <Button
          disabled={!type}
          text="Schedule"
          onClick={(): void => {
            const config = APPOINTMENT_CONFIG[type!];
            if (
              config?.intake ||
              type === "Therapy - Caregiver Session" ||
              type === "Therapy - Patient Session"
            ) {
              let itemType = type;
              if (itemType === "Therapy Clinical Intake") {
                // Both clinical intakes are handled as one in Guide Clinical Intake
                itemType = "Guide Clinical Intake";
              }
              navigation.navigate("IntakeScheduling", {
                type: itemType,
                selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
              });
            } else {
              navigation.navigate("CreateScheduleItem", {
                selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
                selectedStaffId: profile?._id,
              });
            }
          }}
        />
      </Box>
    );
  } else {
    return (
      <Box alignItems="center" direction="row" paddingY={4}>
        <Box width={200}>
          <Button
            iconName="plus"
            text="Add Schedule Item"
            onClick={(): void => {
              navigation.navigate("CreateScheduleItem", {
                selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
                selectedStaffId: profile?._id,
              });
            }}
          />
        </Box>
      </Box>
    );
  }
};
