import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {useGetUsersByIdQuery} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Badge, Box, Button, formatAddress, Text} from "ferns-ui";
import React from "react";

interface PreferredPharmacyProps {
  userId: string;
}

export const PharmaciesPane = ({userId}: PreferredPharmacyProps): React.ReactElement | null => {
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const user = useGetUsersByIdQuery(userId).data;

  if (!user) {
    return null;
  }

  return (
    <Box>
      {(user.preferredPharmacies ?? []).map((pharmacy, index) => (
        <Box
          key={index}
          borderBottom={index < user.preferredPharmacies.length - 1 ? "default" : undefined}
          direction="row"
          justifyContent="between"
          paddingY={2}
          width="100%"
        >
          <Box flex="grow">
            <Box direction="row">
              <Text bold>{pharmacy.name}</Text>
              {pharmacy.primary && (
                <Box marginLeft={2}>
                  <Badge value="Primary" />
                </Box>
              )}
            </Box>
            {Boolean(pharmacy?.address?.city) && (
              <Box paddingY={1}>
                {pharmacy.address && <Text>{formatAddress(pharmacy.address as any)}</Text>}
                {pharmacy.phoneNumber && <Text>{pharmacy.phoneNumber}</Text>}
              </Box>
            )}
          </Box>
        </Box>
      ))}
      <Box direction="row" paddingY={3}>
        <Button
          text="Manage"
          onClick={(): void => {
            navigation.push("PharmacyScreen", {userId});
          }}
        />
      </Box>
    </Box>
  );
};
