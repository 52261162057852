import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useGetAlertInstancesQuery,
  useGetUserSessionsQuery,
  useGetVoicemailsQuery,
  useOfflineToggleMutation,
  useOnlineToggleMutation,
  usePatchUsersByIdMutation,
  usePatchUserSessionsByIdMutation,
  useSelectUnreadUserUpdates,
  useSentryAndToast,
  useSetShowSideDrawer,
  useSetSideDrawerComponentName,
} from "@store";
import {hasFeatureFlag, IsMobileDevice, UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  DateTimeField,
  Heading,
  Icon,
  IconButton,
  IconButtonProps,
  Modal,
  printTime,
  ScrollView,
  SelectField,
  Text,
  Tooltip,
  useTheme,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback, useEffect, useState} from "react";
import {Dimensions, Pressable, View} from "react-native";

import {LogoutButton} from "./LogoutButton";
import {NotificationSchedule} from "./NotificationSchedule";
import {NotificationSettings} from "./NotificationSettings";

const WebPushStatus = (): React.ReactElement | null => {
  const profile = useReadProfile();

  if (!profile || IsMobileDevice || profile.webPushTokens.length) {
    return null;
  }

  if (!hasFeatureFlag(profile, "enableWebPush")) {
    return null;
  }

  return (
    <Box>
      <Box direction="row">
        <Box alignItems="center" justifyContent="center" marginRight={2}>
          <Icon iconName="circle-exclamation" size="md" />
        </Box>
        <Heading size="sm">Web Push Notifications</Heading>
      </Box>
      <Box paddingY={1}>
        <Text>Your web push notifications are not enabled. To attempt to enable them, visit:</Text>
      </Box>
      <Box>
        <Text>
          https://www.notion.so/flourishhealth/Web-Push-Notifications-Fixes-008bc134e0d0444ca138a7794b1d2366?pvs=4
        </Text>
      </Box>
    </Box>
  );
};

// The component goes in the title bar of the Panel, Scheduling,
// and Internal Chat pages to provide a few icons and buttons
export const HeaderRightButtons = ({
  defaultIcons = false,
}: {
  defaultIcons?: boolean;
}): React.ReactElement | null => {
  // TODO: update online status and cancel tasks logout due to expired jwt (blocked)
  const profile = useReadProfile();
  const [updateUser] = usePatchUsersByIdMutation();

  const toast = useToast();
  const sentryAndToast = useSentryAndToast();
  const {theme} = useTheme();
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [selectedTime, setSelectedTime] = useState("60");
  const [offlineDateTime, setOfflineDateTime] = useState(
    DateTime.now()
      .plus({minutes: parseInt(selectedTime)})
      .toISO()
  );

  const [doNotDisturb, setDoNotDisturb] = useState(false);

  const [visibleForPatients, setVisibleForPatients] = useState(false);
  const [visibleForFamilyMembers, setVisibleForFamilyMembers] = useState(false);

  const setShowSideDrawer = useSetShowSideDrawer();
  const setShowSideDrawerName = useSetSideDrawerComponentName();
  const [toggleStatusOnline] = useOnlineToggleMutation();
  const [toggleStatusOffline] = useOfflineToggleMutation();
  const [updateUserSession] = usePatchUserSessionsByIdMutation();
  const {data: unresolvedAlerts} = useGetAlertInstancesQuery(
    profile
      ? {
          archived: false,
          resolved: false,
          page: 1, // We don't show over 25 unresolved
        }
      : skipToken
  );
  const {data: unreadVoicemails} = useGetVoicemailsQuery(
    profile
      ? {
          archived: false,
          read: false,
          ownerId: profile._id,
        }
      : skipToken
  );
  let timezoneConfig: {timezone: string; showTimezone: boolean} | undefined;
  if (profile?.timezone) {
    timezoneConfig = {timezone: profile.timezone, showTimezone: true};
  }

  const unreadUserUpdates = useSelectUnreadUserUpdates();
  const {data: userSessionsData} = useGetUserSessionsQuery({page: 1});
  const userSession = userSessionsData?.data?.find((us) => us.ownerId === profile?._id);

  // Once have a profile, set the user visibility toggles
  useEffect(() => {
    if (!profile?._id) {
      return;
    }
    setVisibleForPatients(Boolean(profile.online?.forPatients));
    setVisibleForFamilyMembers(Boolean(profile.online?.forFamilyMembers));
  }, [profile?.online, profile?._id]);

  // Once have a profile and user session, set the do not disturb toggle
  useEffect(() => {
    if (!profile?._id || !userSession?._id) {
      return;
    }
    setDoNotDisturb(Boolean(userSession.doNotDisturbUntil));
  }, [userSession?.doNotDisturbUntil, profile?._id, userSession?._id]);

  // Show the settings modal and reset the state. Otherwise this is a problem if they've had
  // their app open for a while and the state is stale (offline date time might be in the past).
  const doShowSettingsModal = useCallback((): void => {
    setShowSettingsModal(true);
    // Reset state
    setSelectedTime("60");
    setOfflineDateTime(DateTime.now().plus({minutes: 60}).toISO());
  }, []);

  if (!profile) {
    return null;
  }

  const onlineForPatientsText =
    profile?.online?.forPatients && profile?.online?.scheduledOfflineToggleForPatients
      ? `Online for patients until ${printTime(profile?.online?.scheduledOfflineToggleForMembers, timezoneConfig)}`
      : "Offline for patients";
  const onlineForFamilyMembersText =
    profile?.online?.forFamilyMembers && profile?.online?.scheduledOfflineToggleForFamilyMembers
      ? `Online for family members until ${printTime(
          profile?.online?.scheduledOfflineToggleForFamilyMembers,
          timezoneConfig
        )}`
      : "Offline for family members";

  let settingsIndicatorColor: IconButtonProps["indicator"] | undefined;
  if (IsMobileDevice) {
    // If in DND or online for patients or family members, show the settings indicator on mobile.
    if (Boolean(userSession?.doNotDisturb)) {
      settingsIndicatorColor = "error";
    } else if (profile?.online?.forPatients || profile?.online?.forFamilyMembers) {
      settingsIndicatorColor = "success";
    }
  } else {
    // On web, show an indicator if the user is online and does not have a web push token.
    if (!profile.webPushTokens.length && hasFeatureFlag(profile, "enableWebPush")) {
      settingsIndicatorColor = "error";
    }
  }

  const toggleOnlineOnClick = async (): Promise<void> => {
    let patientErr;
    let familyMemberErr;
    if (visibleForPatients) {
      await toggleStatusOnline({
        _id: profile?._id,
        type: UserTypes.Patient,
        offlineDateTime: DateTime.fromISO(offlineDateTime).toJSDate(),
      })
        .unwrap()
        .catch((error: any) => {
          patientErr = error?.data?.title;
          sentryAndToast("Error setting online status", error);
        });
    }
    if (visibleForFamilyMembers) {
      await toggleStatusOnline({
        _id: profile?._id,
        type: UserTypes.FamilyMember,
        offlineDateTime: DateTime.fromISO(offlineDateTime).toJSDate(),
      })
        .unwrap()
        .catch((error: any) => {
          familyMemberErr = error?.data?.title;
          sentryAndToast("Error setting online status", error);
        });
    }
    if (!patientErr && !familyMemberErr) {
      setShowSettingsModal(false);
      const text =
        visibleForPatients && visibleForFamilyMembers
          ? "Patients and Family Members"
          : visibleForPatients
            ? "Patients"
            : "Family Members";
      const until = printTime(offlineDateTime, timezoneConfig);
      toast.show(`You are now online for ${text} until ${until}`);
    }
  };

  const toggleOfflineOnClick = async (): Promise<void> => {
    let patientErr;
    let familyMemberErr;
    if (profile?.online?.forPatients) {
      await toggleStatusOffline({
        _id: profile?._id,
        // anytime we manually toggle offline we want to clear the task
        type: UserTypes.Patient,
      })
        .unwrap()
        .catch((error: any) => {
          patientErr = error?.data?.title;
          toast.catch(error, patientErr ?? "Error setting offline status");
        });
      if (!patientErr) {
        setVisibleForPatients(false);
      }
    }
    if (profile?.online?.forFamilyMembers) {
      await toggleStatusOffline({
        _id: profile?._id,
        // anytime we manually toggle offline we want to clear the task
        type: UserTypes.FamilyMember,
      })
        .unwrap()
        .catch((error: any) => {
          familyMemberErr = error?.data?.title;
          toast.catch(error, familyMemberErr ?? "Error setting offline status");
          return;
        });
      if (!familyMemberErr) {
        setVisibleForFamilyMembers(false);
      }
    }
    if (!patientErr && !familyMemberErr) {
      setShowSettingsModal(false);
      toast.show(`You are now offline for Patients and Family Members`);
    }
  };

  const renderOnlineStatus = (): React.ReactElement | null => {
    if (profile?.online?.forPatients || profile?.online?.forFamilyMembers) {
      return (
        <Box>
          <Heading size="md">Visibility to Users</Heading>
          <Box paddingY={1}>
            <Text bold>{onlineForPatientsText}</Text>
          </Box>
          <Box paddingY={1}>
            <Text bold>{onlineForFamilyMembersText}</Text>
          </Box>
          <Box maxWidth={200} paddingY={2}>
            <Button text="Go Offline" variant="destructive" onClick={toggleOfflineOnClick} />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Heading size="md">Visibility to Users</Heading>
          <Box paddingX={1} paddingY={1}>
            <Text size="md">
              Use the dropdown or time picker below to set a custom time to remain visible as
              online. Online visibility for internal chat is handled automatically.
            </Text>
          </Box>
          <Box marginTop={3}>
            <Box direction="row" display="flex" paddingX={1} paddingY={1}>
              <Box paddingX={1}>
                <Icon iconName="circle-exclamation" size="md" />
              </Box>
              <Text>Time selection is in your current local timezone.</Text>
            </Box>
          </Box>

          <Box
            direction={IsMobileDevice ? "column" : "row"}
            gap={4}
            justifyContent="between"
            paddingY={2}
            width="100%"
          >
            <Box marginRight={2} width={IsMobileDevice ? "100%" : "40%"}>
              <SelectField
                options={[
                  {label: "1 hour", value: "60"},
                  {label: "2 hours", value: "120"},
                  {label: "3 hours", value: "180"},
                  {label: "4 hours", value: "240"},
                  {label: "5 hours", value: "300"},
                  {label: "6 hours", value: "360"},
                ]}
                requireValue
                value={selectedTime}
                onChange={(value): void => {
                  setSelectedTime(value);
                  const minutes = parseInt(value);
                  const newTime = DateTime.now().plus({minutes}).toISO();
                  console.debug(`Setting offlineDateTime via dropdown to ${newTime}`);
                  setOfflineDateTime(newTime);
                }}
              />
            </Box>
            <Box width={IsMobileDevice ? "100%" : "55%"}>
              <DateTimeField
                type="datetime"
                value={offlineDateTime}
                onChange={(result): void => {
                  // schedule online for a minimum of 5 minutes in the future
                  const fourMinutesFromNow = DateTime.now().plus({minutes: 4});
                  const newDate = DateTime.fromISO(result);
                  if (newDate.isValid && newDate >= fourMinutesFromNow) {
                    console.debug(`Setting offlineDateTime via picker to ${result}`);
                    setOfflineDateTime(result);
                  } else {
                    toast.error(
                      "Scheduled offline toggle time must be at least 5 minutes in the future"
                    );
                  }
                }}
              />
            </Box>
          </Box>
          <Box paddingY={2}>
            <Box marginBottom={2}>
              <BooleanField
                title="Go online for patients"
                value={visibleForPatients}
                variant="title"
                onChange={(): void => {
                  setVisibleForPatients(!visibleForPatients);
                }}
              />
            </Box>
            <BooleanField
              title="Go online for family members"
              value={visibleForFamilyMembers}
              variant="title"
              onChange={(): void => {
                setVisibleForFamilyMembers(!visibleForFamilyMembers);
              }}
            />
          </Box>
          <Box maxWidth={200} paddingY={2}>
            <Button
              disabled={
                (!visibleForFamilyMembers && !visibleForPatients) ||
                profile?.online?.forPatients ||
                profile?.online?.forFamilyMembers
              }
              text="Go Online"
              onClick={toggleOnlineOnClick}
            />
          </Box>
        </Box>
      );
    }
  };

  const renderNotificationSettings = (): React.ReactElement | null => {
    return (
      <Box>
        <Box gap={4}>
          <Heading size="md">Notifications</Heading>
          {/* todo - add sound selection dropdown when sounds become available */}
          {Boolean(hasFeatureFlag(profile, "enableWebPush")) && <NotificationSettings />}
        </Box>
        <NotificationSchedule />
        <Box marginBottom={2}>
          <BooleanField
            title="Do Not Disturb"
            value={doNotDisturb}
            onChange={async (): Promise<void> => {
              setDoNotDisturb(!doNotDisturb);
              try {
                await updateUserSession({
                  id: userSession!._id,
                  body: {
                    // TODO: figure out how to send null to clear fields with the SDK.
                    doNotDisturbUntil: doNotDisturb
                      ? (null as any)
                      : DateTime.now().plus({days: 1}).toISO()!,
                  },
                }).unwrap();
              } catch (error) {
                toast.catch(error, "Error setting do not disturb");
                return;
              }
              toast.show(`Do Not Disturb ${doNotDisturb ? "disabled" : "enabled"}`);
            }}
          />
        </Box>
        <Box marginBottom={2}>
          <Text>
            Disable all push notifications (including for panel messages) on desktop and mobile
            until for one day. If on, this overrides the notification schedule above.
          </Text>
        </Box>
        <Box marginBottom={2}>
          <BooleanField
            title="Out of Office"
            value={profile.outOfOffice ?? false}
            onChange={async (value: boolean): Promise<void> => {
              try {
                await updateUser({
                  id: profile._id,
                  body: {outOfOffice: value},
                }).unwrap();
              } catch (error: any) {
                toast.catch(error, "Error setting do not disturb");
                return;
              }
              toast.show(`Out of Office ${profile?.outOfOffice ? "disabled" : "enabled"}`);
            }}
          />
        </Box>
        <Box marginBottom={2}>
          <Text>
            Disable all push notifications and display a notice to patients and family members that
            you are out of the office. They will not be able to send you messages while you are out
            of the office.
          </Text>
        </Box>
      </Box>
    );
  };

  const renderSettingsModal = (): React.ReactElement | null => {
    const windowHeight = Dimensions.get("window").height;
    return (
      <Modal
        size="md"
        title="Settings"
        visible={showSettingsModal}
        onDismiss={(): void => {
          setShowSettingsModal(false);
        }}
      >
        <Box
          alignSelf="center"
          maxHeight={windowHeight * (2 / 3)}
          maxWidth="100%"
          scroll
          width="100%"
        >
          <ScrollView contentContainerStyle={{gap: 24}}>
            <WebPushStatus />
            <Box>{renderOnlineStatus()}</Box>
            <Box>{renderNotificationSettings()}</Box>
            <Box direction="row" display="flex">
              <Box paddingX={1}>
                <Icon iconName="circle-exclamation" size="md" />
              </Box>
              <Text>Logging out will set patient and family member visibility to offline.</Text>
            </Box>
            <LogoutButton />
          </ScrollView>
        </Box>
      </Modal>
    );
  };

  const renderDesktopIcons = (): React.ReactElement | null => {
    if (IsMobileDevice || defaultIcons) {
      return null;
    }
    return (
      <>
        <Tooltip idealPosition="bottom" text={IsMobileDevice ? undefined : onlineForPatientsText}>
          <IconButton
            accessibilityLabel="online for patients"
            iconName="child"
            indicator={profile?.online?.forPatients ? "success" : "neutral"}
            variant="navigation"
            onClick={doShowSettingsModal}
          />
        </Tooltip>
        <Tooltip
          idealPosition="bottom"
          text={IsMobileDevice ? undefined : onlineForFamilyMembersText}
        >
          <IconButton
            accessibilityLabel="online for family members"
            iconName="user-group"
            indicator={profile?.online?.forFamilyMembers ? "success" : "neutral"}
            variant="navigation"
            onClick={doShowSettingsModal}
          />
        </Tooltip>
        <Tooltip
          idealPosition="bottom"
          text={Boolean(userSession?.doNotDisturb) ? "Do Not Disturb" : "Online For Internal Chat"}
        >
          <Pressable onPress={doShowSettingsModal}>
            <Icon
              color={Boolean(userSession?.doNotDisturb) ? "error" : "success"}
              iconName={Boolean(userSession?.doNotDisturb) ? "circle-minus" : "circle"}
              size="lg"
            />
          </Pressable>
        </Tooltip>
        <View
          style={{
            height: "100%",
            width: 1,
            backgroundColor: theme.surface.neutral,
          }}
        />
      </>
    );
  };

  return (
    <Box alignItems="center" direction="row" gap={4} paddingX={IsMobileDevice ? 1 : 2}>
      {renderDesktopIcons()}
      {/* // Default Icons */}
      <Tooltip idealPosition="bottom" text={IsMobileDevice ? undefined : "Settings"}>
        <IconButton
          accessibilityLabel="settings"
          iconName="gear"
          indicator={settingsIndicatorColor}
          variant="navigation"
          onClick={doShowSettingsModal}
        />
      </Tooltip>
      {IsMobileDevice ? null : (
        <Tooltip idealPosition="bottom" text="Voicemails">
          <IconButton
            accessibilityLabel="voicemails"
            iconName="voicemail"
            indicator={Boolean(unreadVoicemails?.data?.length ?? 0) ? "primary" : undefined}
            indicatorText={String(unreadVoicemails?.data?.length ?? "")}
            variant="navigation"
            onClick={(): void => {
              setShowSideDrawerName("VoicemailsView");
              setShowSideDrawer(true);
            }}
          />
        </Tooltip>
      )}
      <Tooltip idealPosition="bottom" text={IsMobileDevice ? undefined : "User Updates"}>
        <IconButton
          accessibilityLabel="user-updates"
          iconName="bell"
          indicator={unreadUserUpdates ? "warning" : undefined}
          variant="navigation"
          onClick={(): void => {
            setShowSideDrawerName("UserUpdatesView");
            setShowSideDrawer(true);
          }}
        />
      </Tooltip>
      <Tooltip idealPosition="bottom" text={IsMobileDevice ? undefined : "Alerts"}>
        <IconButton
          accessibilityLabel="alerts"
          iconName="triangle-exclamation"
          indicator={Boolean(unresolvedAlerts?.data?.length ?? 0) ? "warning" : undefined}
          indicatorText={unresolvedAlerts?.data?.length ?? 0}
          variant="navigation"
          onClick={(): void => {
            setShowSideDrawerName("AlertsView");
            setShowSideDrawer(true);
          }}
        />
      </Tooltip>
      {renderSettingsModal()}
    </Box>
  );
};
