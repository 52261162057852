export function createElement(name, props = {}, children = []) {
    const element = document.createElement(name);
    for (const [name, value] of Object.entries(props)) {
        if (name === 'style') {
            Object.assign(element.style, props.style);
        }
        else {
            element.setAttribute(name, value);
        }
    }
    for (const child of Array.isArray(children) ? children : [children]) {
        element.append(child);
    }
    return element;
}
