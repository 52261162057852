import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  GetPrescriptionsRes,
  useGetDosespotPrescriptionLogByPrescriptionIdQuery,
  useGetPrescriptionsQuery,
  useGetUsersByIdQuery,
  usePostDosespotSyncPatientMutation,
  usePostRefreshPrescriptionsMutation,
} from "@store";
import {StaffStackParamList} from "@types";
import {isSoftwareEngineer, UserTypes} from "@utils";
import {
  Box,
  Button,
  Link,
  Modal,
  printDate,
  printDateAndTime,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableText,
  Text,
  useToast,
} from "ferns-ui";
import React, {ReactElement, useState} from "react";

import {useReadProfile} from "../hooks";

export type PrescriptionDataType = NonNullable<GetPrescriptionsRes["data"]>;

const PrescriptionTable = ({
  prescriptions,
  onPrescriptionClick,
}: {
  prescriptions: PrescriptionDataType;
  onPrescriptionClick: (prescriptionId: string) => void;
}): ReactElement => {
  if (prescriptions.length === 0) {
    return (
      <Box alignItems="center">
        <Text>No prescriptions found</Text>
      </Box>
    );
  }
  return (
    <Table columns={[200, 200, 200, 200]}>
      <TableHeader>
        <TableHeaderCell index={0} title="Medication" />
        <TableHeaderCell index={1} title="Date Prescribed" />
        <TableHeaderCell index={3} title="Expiration Date" />
        <TableHeaderCell index={4} title="Status" />
      </TableHeader>
      {prescriptions.map((p) => (
        <TableRow key={p._id}>
          <TableText value={p.medicationName} />
          <TableText value={printDate(p.writtenDate)} />
          <TableText value={printDate(p.expirationDate)} />
          <Link
            text={p.status}
            onClick={(): void => {
              onPrescriptionClick(p._id);
            }}
          />
        </TableRow>
      ))}
    </Table>
  );
};

export const Prescriptions = ({userId}: {userId: string}): ReactElement | null => {
  const staffUser = useReadProfile();
  const {data: patient} = useGetUsersByIdQuery(userId);
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const {data: prescriptions} = useGetPrescriptionsQuery({patientId: userId});
  const [refreshPrescriptions] = usePostRefreshPrescriptionsMutation();
  const [syncPatient] = usePostDosespotSyncPatientMutation();
  const toast = useToast();
  const [errorDetails, setErrorDetails] = useState<{
    message: string;
    details: string;
  } | null>(null);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState<string | null>(null);
  const [showPrescriptionLogModal, setShowPrescriptionLogModal] = useState(false);

  if (!patient) {
    return null;
  }

  if (!patient?.dosespotPatientId) {
    return (
      <Box alignItems="start">
        <Button
          text="Enable Prescriptions"
          variant="primary"
          onClick={async (): Promise<void> => {
            try {
              await syncPatient({patientUserId: userId}).unwrap();
              toast.success("User synced");
              setErrorDetails(null);
            } catch (error: any) {
              setErrorDetails({
                message: "Error configuring user",
                details: error.data.detail,
              });
            }
          }}
        />
        <Box direction="column" gap={2} marginTop={2}>
          {errorDetails && (
            <Text bold color="error" size="lg">
              {errorDetails.message}
            </Text>
          )}
          {errorDetails && <Text color="error">{errorDetails.details}</Text>}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <PrescriptionLogModal
        prescriptionId={selectedPrescriptionId}
        visible={showPrescriptionLogModal}
        onDismiss={() => {
          setShowPrescriptionLogModal(false);
        }}
      />
      <Box>
        <Box width="100%">
          {prescriptions?.data && (
            <PrescriptionTable
              prescriptions={prescriptions.data}
              onPrescriptionClick={(prescriptionId): void => {
                setSelectedPrescriptionId(prescriptionId);
                setShowPrescriptionLogModal(true);
              }}
            />
          )}
        </Box>
        <Box direction="row" paddingY={3}>
          {Boolean(staffUser?.dosespotClinicianId) && (
            <Box marginRight={2}>
              <Button
                text="Manage"
                variant="primary"
                onClick={
                  (): void =>
                    navigation.navigate("DoseSpotUI", {
                      patientUserId: userId,
                    })
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </Box>
          )}
          {/* Only show sync buttons for super users and software engineers. It should auto sync via webhooks, so shouldn't be needed unless a failure occurs. */}
          {isSoftwareEngineer(staffUser) && (
            <>
              <Box marginRight={2}>
                <Button
                  text="Sync Prescriptions"
                  variant="muted"
                  onClick={async (): Promise<void> => {
                    await refreshPrescriptions({patientUserId: userId});
                  }}
                />
              </Box>
              <Box>
                <Button
                  text="Sync User"
                  variant="muted"
                  onClick={async (): Promise<void> => {
                    try {
                      await syncPatient({patientUserId: userId}).unwrap();
                      toast.success("User synced");
                    } catch (error: any) {
                      toast.catch(error, "Error syncing user");
                    }
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const PrescriptionLogModal = ({
  prescriptionId,
  visible,
  onDismiss,
}: {
  prescriptionId: string | null;
  visible: boolean;
  onDismiss: () => void;
}): ReactElement => {
  const {data: prescriptionLog, isLoading} = useGetDosespotPrescriptionLogByPrescriptionIdQuery(
    prescriptionId ?? skipToken
  );

  const sortedPrescriptionLog = [...(prescriptionLog ?? [])].sort((a, b) => {
    return new Date(b.DateTimeStamp ?? "").getTime() - new Date(a.DateTimeStamp ?? "").getTime();
  });

  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      {isLoading ? (
        <Box>
          <Text>Loading...</Text>
        </Box>
      ) : (
        <Box>
          <Table columns={["15%", "15%", "17%", "15%", "15%", "15%"]}>
            <TableHeader>
              <TableHeaderCell index={0} title="Status" />
              <TableHeaderCell index={1} title="Medication Status" />
              <TableHeaderCell index={2} title="Date" />
              <TableHeaderCell index={3} title="Additional Info" />
              <TableHeaderCell index={4} title="User" />
              <TableHeaderCell index={5} title="Was Refill Requested" />
            </TableHeader>
            {sortedPrescriptionLog.map((log) => (
              <TableRow key={log.DateTimeStamp}>
                <TableText value={log.Status ?? ""} />
                <TableText value={log.MedicationStatus ?? ""} />
                <TableText value={printDateAndTime(log.DateTimeStamp)} />
                <TableText value={log.AdditionalInfo ?? ""} />
                <TableText value={log.User ?? ""} />
                <TableText value={log.WasRefillRequest ? "Yes" : "No"} />
              </TableRow>
            ))}
          </Table>
        </Box>
      )}
    </Modal>
  );
};
