import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Form,
  FormInstance,
  ScheduleItem,
  useGetBillingExplorerQuery,
  useGetDiagnosesQuery,
  useGetUsersByIdQuery,
  useGetUserStatusesQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {Box, printDate, printOnlyDate, TapToEdit, Text} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback, useContext, useEffect, useState} from "react";

import {GOOGLE_MAPS_API_KEY} from "../constants";
import {StaffStackParamList} from "../types";
import {AutoTapToEdit} from "./AutoTapToEdit";
import {AutoTapToEditInsurance} from "./AutoTapToEditInsurance";
import {DataExplorer} from "./DataExplorer";
import {TapToEditGoogleDrive} from "./TapToEditGoogleDrive";
import {TapToEditSchedule} from "./TapToEditSchedule";
import {UserExplorerContext} from "./UserExplorerContext";

const DataRow = ({title, data}: {title: string; data?: string}): React.ReactElement => {
  return (
    <Box direction="row" paddingY={1} width="100%">
      <Box width={140}>
        <Text bold>{title}:</Text>
      </Box>
      <Box width={460}>
        <Text>{data ?? ""}</Text>
      </Box>
    </Box>
  );
};
const BillingExplorerDrawer: React.FC<{extraData: any; dataId: string; refetch: () => void}> = ({
  extraData,
}) => {
  const user = extraData;
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const {data: diagnoses} = useGetDiagnosesQuery(
    user?.diagnosis?.diagnoses ? {_id: {$in: user.diagnosis.diagnoses ?? []}} : skipToken
  );
  const [updateUser] = usePatchUsersByIdMutation();

  const {data: userData} = useGetUsersByIdQuery(user._id);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const {data: userStatusData} = useGetUserStatusesQuery({});

  const printScheduleItems = useCallback((item: ScheduleItem): string => {
    return `${printOnlyDate(item.startDatetime, {defaultValue: ""})} - ${item.title} (${item.attendanceStatus})`;
  }, []);

  const printFormInstance = useCallback((form: FormInstance): string => {
    return `${printOnlyDate(form.serviceDate, {defaultValue: ""})} - ${(form.form as Form).name} ${form.attendanceStatus ? `(${form.attendanceStatus})` : ""}`;
  }, []);

  // Set the user data once we finish fetching
  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);

  if (!currentUser) {
    return null;
  }

  const props = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
  };

  const statusName =
    userStatusData?.data?.find((us) => us._id === currentUser.statusId)?.name ?? "No Status";
  let status = `${statusName}`;
  // Check if discharge date is within the next 30 days using luxon, if so, add to status
  if (currentUser.growth.dischargeDate) {
    const dischargeDate = DateTime.fromISO(currentUser.growth.dischargeDate);
    const now = DateTime.now();
    const daysUntilDischarge = dischargeDate.diff(now, "days").days;
    if (daysUntilDischarge < 60) {
      status = `${status} - Discharge in ${daysUntilDischarge} days`;
    }
  }

  return (
    <Box padding={4} width={600}>
      <AutoTapToEdit field="name" title="Name" {...props} />
      <Text>Status: {status}</Text>
      <AutoTapToEdit field="billingInfo.firstName" title="First Name (billing)" {...props} />
      <AutoTapToEdit field="billingInfo.lastName" title="Last Name (billing)" {...props} />
      <AutoTapToEdit field="birthday" title="Date of Birth" type="date" {...props} />
      <Box borderBottom="default" paddingY={1}>
        <TapToEdit
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          includeCounty
          setValue={(value): void => {
            setCurrentUser({...currentUser, address: value});
          }}
          title="Address"
          type="address"
          value={currentUser?.address}
          onSave={async (address): Promise<void> => {
            await updateUser({
              id: currentUser._id,
              body: {address},
            });
          }}
        />
      </Box>
      <AutoTapToEdit field="billingInfo.gender" title="Billing Gender" {...props} />
      <AutoTapToEdit field="phoneNumber" title="Phone Number" {...props} />
      <AutoTapToEditInsurance
        currentUser={currentUser}
        title="Insurance"
        value={currentUser.billingInfo?.insurancePlan}
        {...props}
      />
      <AutoTapToEdit field="billingInfo.memberId" {...props} />
      <AutoTapToEdit field="billingInfo.groupId" {...props} />
      <AutoTapToEdit field="billingInfo.healthPlanId" {...props} />
      <AutoTapToEdit field="billingInfo.authorizationCode" {...props} />
      <AutoTapToEdit field="billingInfo.medicaidNumber" {...props} />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.therapyIntake?.scheduleItemId}
        type="Therapy Intake"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.clinicalIntake?.scheduleItemId}
        type="Clinical Intake"
      />

      <AutoTapToEdit
        field="growth.enrolledDate"
        transform={(value) => (value ? printDate(value) : "")}
        {...props}
      />
      {/* TODO: invalidate engagement data when changing this */}
      <AutoTapToEdit
        field="growth.serviceStartDate"
        transform={(value) => (value ? printDate(value) : "")}
        {...props}
      />
      <AutoTapToEdit
        field="growth.dischargeDate"
        transform={(value) => (value ? printDate(value) : "")}
        {...props}
      />
      <TapToEditGoogleDrive userId={currentUser._id} />
      <DataRow
        data={diagnoses?.data?.map((d: any) => `${d.icd10cm}: ${d.description} `).join("\n")}
        title="Diagnoses"
      />
      <Box borderBottom="default" paddingY={2}>
        <Text bold>Schedule Items: {user.allScheduleItems.length === 0 ? "None" : ""}</Text>
        {user.allScheduleItems.map((item: ScheduleItem) => (
          <Box
            key={item._id}
            accessibilityHint="View schedule item"
            accessibilityLabel="View schedule item"
            onClick={() => {
              navigation.navigate("CreateScheduleItem", {scheduleItemId: item._id});
            }}
          >
            <Text underline>{printScheduleItems(item)}</Text>
          </Box>
        ))}
      </Box>
      <Box borderBottom="default" paddingY={2}>
        <Text bold>Notes: {user.allClinicalForms.length === 0 ? "None" : ""}</Text>
        {user.allClinicalForms.map((item: FormInstance) => (
          <Box
            key={item._id}
            accessibilityHint="View note"
            accessibilityLabel="View note"
            onClick={() => {
              navigation.navigate("ManageFormScreen", {formId: item._id, readOnly: true});
            }}
          >
            <Text underline>{printFormInstance(item)}</Text>
          </Box>
        ))}
      </Box>
      <Box borderBottom="default" paddingY={2}>
        <Text bold>Critical Event Notes: {user.criticalEventForms.length === 0 ? "None" : ""}</Text>
        {user.criticalEventForms.map((item: FormInstance) => (
          <Box
            key={item._id}
            accessibilityHint="View note"
            accessibilityLabel="View note"
            onClick={() => {
              navigation.navigate("ManageFormScreen", {formId: item._id, readOnly: true});
            }}
          >
            <Text underline>{printFormInstance(item)}</Text>
          </Box>
        ))}
      </Box>
      <DataRow
        data={user.engagementMonths
          ?.map(
            (m: any, i: number) =>
              `Month ${i + 1}: ${printOnlyDate(m.start, {defaultValue: ""})} - ${printOnlyDate(
                m.end,
                {
                  defaultValue: "---",
                }
              )}`
          )
          .join("\n")}
        title="Engagement Months"
      />
    </Box>
  );
};

interface BillingExplorerProps {}

export const BillingExplorer: React.FC<BillingExplorerProps> = () => {
  const {carePodId, billingDate} = useContext(UserExplorerContext)!;
  if (!carePodId) {
    return (
      <Box alignItems="center" flex="grow" paddingY={12}>
        <Text bold>Select a care pod to see billing data</Text>
      </Box>
    );
  }

  return (
    <DataExplorer
      drawerComponent={BillingExplorerDrawer}
      filters={{
        carePodId,
        date: billingDate,
      }}
      hook={useGetBillingExplorerQuery}
      page={1}
    />
  );
};
