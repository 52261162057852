import { EJSON } from 'bson';
import { isPlainObject } from '../utils';
/**
 * A class extender for common chart methods
 * @param Sender the parent class to be extended
 */
export function CommonChart(Sender) {
    return class extends Sender {
        /**
         * Sets a highlight to the state of the chart entity in the Charts app component.
         * The chart entity can be an embedded chart or an embedded dashboard chart.
         * The highlight gets applied to the embedded chart or dashboard chart.
         *
         * This is the exact same object that can be used in 'setFilter'.
         * However, it [doesn't support some query expressions] (https://www.mongodb.com/docs/charts/filter-embedded-charts/#filter-syntax)
         * And there are some specifics about what is highlightable (https://www.mongodb.com/docs/charts/highlight-chart-elements/)
         *
         * @param value The highlight object to be applied to the chart
         * @returns a promise that resolves once the highlight is saved and the component rerendered
         */
        async setHighlight(value) {
            if (!isPlainObject(value)) {
                return Promise.reject('highlight property value should be an object');
            }
            await this._send('set', 'highlight', EJSON.stringify(value, { relaxed: false }));
        }
        /**
         * Returns the current highlight applied to the embedded chart or dashboard chart
         * The highlight value is taken from the state of the chart entity in the Charts app component.
         *
         * @returns a promise that resolves once the highlight is taken from the Charts state
         */
        async getHighlight() {
            const [result] = await this._send('get', 'highlight');
            return isPlainObject(result)
                ? result
                : Promise.reject('unexpected response received from iframe');
        }
        /**
         * @returns the data of the embedded chart or dashboard chart
         */
        async getData() {
            const [result] = await this._send('get', 'data');
            return typeof result === 'object' && result !== null
                ? result
                : Promise.reject('unexpected response received from iframe');
        }
    };
}
